import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import App from './containers/App/App';
import WorkEditor from './customApp/containers/Workeditor';
import WorkPublisher from './customApp/containers/WorkPublisher';


import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={props => (isLoggedIn ? (
            <Component {...props} />
        ) : (
            <Redirect
                to={{
                    pathname: '/signin',
                    state: { from: props.location },
                }} />
        ))
        } />
);
const PublicRoutes = ({ history, isLoggedIn }) => (
    <ConnectedRouter history={history}>
        <div>
            <Route
                exact
                path="/"
                component={asyncComponent(() => import('./containers/Page/signin'))} />
            <Route
                exact
                path="/404"
                component={asyncComponent(() => import('./containers/Page/404'))} />
            <Route
                exact
                path="/500"
                component={asyncComponent(() => import('./containers/Page/500'))} />
            <Route
                exact
                path="/signin"
                component={asyncComponent(() => import('./containers/Page/signin'))} />
            <Route
                exact
                path="/onboarding"
                component={asyncComponent(() => import('./containers/Page/onboarding'))} />
            <Route
                exact
                path="/signup"
                component={asyncComponent(() => import('./containers/Page/signup'))} />
            <Route
                exact
                path="/forgotpassword"
                component={asyncComponent(() => import('./containers/Page/forgotPassword'))} />
            <Route
                exact
                path="/resetpassword"
                component={asyncComponent(() => import('./containers/Page/resetPassword'))} />


            <RestrictedRoute
                path="/dashboard"
                component={App}
                isLoggedIn={isLoggedIn} />

            <RestrictedRoute
                path="/WorkEditor"
                component={WorkEditor}
                isLoggedIn={isLoggedIn} />

            <RestrictedRoute
                path="/WorkViewr"
                component={WorkPublisher}
                isLoggedIn={isLoggedIn} />
        </div>
    </ConnectedRouter>
);

export default connect(state => ({
    isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);

// 2nd comment for Git
