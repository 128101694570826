import React, { Component } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import '../mobileCont.css';
import {
    DatePicker,
    TimePicker,

} from 'antd';
import moment from 'moment';
import SingleTemplateAction from '../../../../redux/SingleTemplate/actions';
import IntlMessages from '../../../../components/utility/intlMessages';


const dateFormat = 'DD/MM/YYYY';
const date = new Date();
const tomorow = `${date.getDate() + 1}/${date.getMonth() + 1}/${date.getFullYear()}`;
const timeFormat = 'HH:mm';
const startTime = '08:00';
const {
    LoadSingleTemplate,
} = SingleTemplateAction;

class SelectDataAndTime extends Component {

    render() {
        const  { onDateChange, onTimeChange, title } = this.props;
        return (
            <div>
                <div className="isoHelperText">
                    {!!title && title}
                    {!title && <IntlMessages id="WorkViewr.index.selectDateAndTime" />}
                </div>
                <div className="isoInputWrapper">
                    <DatePicker
                        defaultValue={moment(tomorow, dateFormat)}
                        format={dateFormat}
                        onChange={onDateChange} />
                </div>
                <div className="isoInputWrapper">
                    <TimePicker
                        defaultValue={moment(startTime, timeFormat)}
                        onChange={onTimeChange}
                        format={timeFormat} />
                </div>
            </div>
        );
    }
}

SelectDataAndTime.propTypes = {
    onDateChange: object.isRequired,
    onTimeChange: object.isRequired,
};

function mapStateToProps(state) {
    return {
        ...state,
        isLoading: state.SingleTemplate.TempisLoading,
    };
}

export default connect(mapStateToProps, {
    LoadSingleTemplate,
})(SelectDataAndTime);
