import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleConfig } from '../../../settings/index';


class PreviewMap extends Component {
    constructor(props) {
        super(props);
        // const sorted = props.activity.sort((a, b) => a.order > b.order);
        this.state = {
            lat: 59.95,
            lng: 30.33,
        };
    }


  static defaultProps = {
      center: {
          lat: 59.95,
          lng: 30.33,
      },
      zoom: 11,
  };

  onMapClicked = (e) => {
      this.setState(e);
  };

    renderMarkers = (map, maps) => {
        const marker = new maps.Marker({
            position: { lat: this.state.lat, lng: this.state.lng },
            map,
            title: 'Hello World!',
        });
        return marker;
    };

    render() {
        return (
        // Important! Always set the container height explicitly
            <div style={{ height: 150, width: 100 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleConfig.apiKey }}
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    // eslint-disable-next-line indent
                 />
            </div>
        );
    }
}

export default PreviewMap;
