import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleConfig } from '../../../settings/index';

const AnyReactComponent = ({ text }) => <div style={{backgroundColor:'red', minHeight: 20, minWidth: 20}}>{text}</div>;

class SimpleMap extends Component {
    constructor(props) {
        super(props);
        // const sorted = props.activity.sort((a, b) => a.order > b.order);
        this.state = {
            lat: props.lat,
            lng: props.lng,
        };
    }


  static defaultProps = {
      center: {
          lat: 59.95,
          lng: 30.33,
      },
      zoom: 11,
  };

  onMapClicked = (e) => {
      this.setState(e);
      this.props.selectedLocation(e);
  };

    renderMarkers = (map, maps) => {
        const marker = new maps.Marker({
            position: { lat: this.state.lat, lng: this.state.lng },
            map,
            title: 'Hello World!',
        });
        return marker;
    };

    render() {
        return (
        // Important! Always set the container height explicitly
            <div style={{ height: 300, width: 300 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleConfig.apiKey }}
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    onClick={e => this.onMapClicked(e)}
                >
                    <AnyReactComponent
                        lat={this.state.lat}
                        lng={this.state.lng}
                        text="ErgoPoint"/>
                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;
