/* eslint-disable func-names */
import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import { request, callDF } from '../../network/request';
import { ergoBe } from '../../settings/index';
import Notification from '../../components/notification';



const urlsession = ergoBe.beUrl + ergoBe.Session;
export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get('idToken');
        const response = yield callDF(urlsession);

        if (response.email) {
            const ergoUrl = `${ergoBe.beUrl
                + ergoBe.ergoUser}?related=GetUserCompany%2CGetUserGroups&filter=email=${
                response.email
            }`;

            const userErgo = yield callDF(ergoUrl);
            if (response.email && userErgo.resource) {
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token,
                    profile: 'Profile',
                    dfUser: response,
                    ergoUser: userErgo.resource[0],
                });
            }
        } else {
            clearToken();
           // yield put(push('/'));
            yield put({ type: actions.LOGIN_ERROR });
        }
    });
}
export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem('id_token', payload.token);
    });

}

export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function* (reqload) {
        const options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-DreamFactory-API-Key': ergoBe.apptoken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: reqload.user,
                password: reqload.pass,
            }),
        };
        const response = yield request(urlsession, options);

        if (response.email) {
            const ergoUrl = `${ergoBe.beUrl
                + ergoBe.ergoUser}?related=GetUserCompany%2CGetUserGroups&filter=email=${
                response.email
            }`;

            yield put({
                type: actions.LOGIN_SUCCESS,
                token: response.session_token,
                profile: 'Profile',
            });
            const token = getToken().get('idToken');
            const userErgo = yield callDF(ergoUrl);
            if (response.email && userErgo.resource) {
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token,
                    profile: 'Profile',
                    dfUser: response,
                    ergoUser: userErgo.resource[0],
                });
            }
        } else {
            clearToken();
            //    yield put(push('/'));
            yield put({ 
                type: actions.LOGIN_ERROR,
                message: response.error.message
            });
            Notification(
                'error',
                JSON.stringify(response.error.message),
             
            );
        }
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
        clearToken();
        yield put(push('/'));
    });
}



export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        clearToken();
        yield put(push('/'));
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
    ]);
}
