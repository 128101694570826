import CompleteActivityActions from './actions';

const CompleteActivity = [];
const initState = {
    CompleteActivity,
    seectedId: [],
    editView: false,
    CompleteActivityisLoading: false,
};

export default function CompleteActivityReducer(state = initState, action) {
    switch (action.type) {
    case CompleteActivityActions.CHANGE_CompleteActivity:
        return {
            ...state,
            seectedId: action.id,
            editView: false,
        };
    case CompleteActivityActions.ADD_CompleteActivity:
        return {
            ...state,
            CompleteActivity: action.CompleteActivity,
            seectedId: action.selectedId,
            editView: true,
        };
    case CompleteActivityActions.EDIT_CompleteActivity:
        return {
            ...state,
            CompleteActivity: action.CompleteActivity,
        };
    case CompleteActivityActions.DELETE__CompleteActivity:
        return {
            ...state,
            CompleteActivity: action.CompleteActivity,
            seectedId: action.seectedId,
        };
    case CompleteActivityActions.LOAD_CompleteActivity:
        return {
            ...state,
            CompleteActivityisLoading: true,
        };
    case CompleteActivityActions.CompleteActivity_LOADED:
        return {
            ...state,
            CompleteActivity: action.CompleteActivity,
            CompleteActivityisLoading: false,
        };
    case CompleteActivityActions.EDIT_VIEW:
        return {
            ...state,
            editView: action.view,
        };
    default:
        return state;
    }
}
export { CompleteActivity };
