import { Templates } from './reducer';

function ascendingSort(Templates1, Templates2) {
    const name1 = Templates1.name ? Templates1.name.toUpperCase() : '~';
    const name2 = Templates2.name ? Templates2.name.toUpperCase() : '~';
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const TemplatesActions = {
    LOAD_Templates: 'LOAD_Templates',
    Templates_LOADED: 'Templates_LOADED',
    ADD_Templates: 'ADD_Templates',
    EDIT_Templates: 'EDIT_Templates',
    DELETE__Templates: 'DELETE__Templates',
    CHANGE_Templates: 'CHANGE_Templates',
    EDIT_VIEW: 'EDIT_VIEW',

    changeTemplates: id => ({
        type: TemplatesActions.CHANGE_Templates,
        id,
    }),
    
    addTemplates: () => {
        const newTemplates = {
            id: new Date(),
            firstName: '',
            avatar: Templates[new Date() % 10].avatar,
            LastName: '',
            mobile: '',
            home: '',
            name: '',
            company: '',
            work: '',
            note: '',

        };
        return (dispatch, getState) => {
            dispatch({
                type: TemplatesActions.ADD_Templates,
                Templates: [...getState().Templates.Templates, newTemplates],
                selectedId: newTemplates.id,
            });
        };
    },
    
    editTemplates: newTemplates => (dispatch, getState) => {
        const Templates = getState().Templates.Templates;
        const newTemplates = [];
        Templates.forEach((Templates) => {
            if (Templates.id === newTemplates.id) {
                newTemplates.push(newTemplates);
            } else {
                newTemplates.push(Templates);
            }
        });
        dispatch({
            type: TemplatesActions.EDIT_Templates,
            Templates: newTemplates.sort(ascendingSort),
        });
    },
    
    deleteTemplates: id => (dispatch, getState) => {
        const Templates = getState().Templates.Templates;
        const seectedId = getState().Templates.seectedId;
        const newTemplates = [];
        Templates.forEach((Templates) => {
            if (Templates.id === id) {
            } else {
                newTemplates.push(Templates);
            }
        });
        dispatch({
            type: TemplatesActions.DELETE__Templates,
            Templates: newTemplates,
            seectedId: id === seectedId ? undefined : seectedId,
        });
    },

    viewChange: view => ({
        type: TemplatesActions.EDIT_VIEW,
        view,
    }),

    LoadTemplates: id => (dispatch) => {
        dispatch({
            type: TemplatesActions.LOAD_Templates,
            compid: id,
            Templates,
        });
    },



    TemplatesLoaded: () => ({
        type: TemplatesActions.Templates_LOADED,
        Templates,

    }),
};
export default TemplatesActions;
