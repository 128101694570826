/* eslint-disable func-names */
import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import TemplatesActions from './actions';
import loginerror from '../auth/actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';


export function* addTemplates() {
    yield takeEvery(TemplatesActions.ADD_Templates, function* () {});
}
export function* editTemplates() {
    yield takeEvery(TemplatesActions.EDIT_Templates, function* () {});
}
export function* deleteTemplates() {
    yield takeEvery(TemplatesActions.DELETE__Templates, function* () {});
}
// TO DO LOAD WITH USER ID
export function* LoadTemplates() {
    yield takeEvery(TemplatesActions.LOAD_Templates, function* (data) {
        const owenerid = data.compid;
        const templates = `${ergoBe.beUrl}ergoprop/_table/activitiestemplates?filter=(status=TEMPLATE)%20and%20(owner_userid=${owenerid})`;
        const response = yield callDF(templates);
        if (response.resource) {
            yield put({
                type: TemplatesActions.Templates_LOADED,
                Templates: response.resource,
            });
        } else {
            yield loginerror();
        }
    });
}
export default function* rootSaga() {
    yield all([fork(LoadTemplates)]);
}
