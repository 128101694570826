import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input, Typography, Checkbox,
} from 'antd';
// import IntlMessages from '../../../components/utility/intlMessages';
import theme from '../../../../../settings/themes/themedefault';
import ButtonsComponent from '../ButtonsComponent';

const PanelHeader = {
    fontWeight: '300',
    fontSize: '0.8em',
    paddingTop: '0.2em',
    fontFamily: theme.fonts.primary,

};
// YesNo QUESTION OR ASSIGMENT COMPONENTS
class GPS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatory: props.element.mandatory,
            title: props.element.element_title,
            textNoFilled: true,
        };
    }

    componentDidMount() {
        const title = this.props.element.element_title;

        if (title !== null) {
            this.setState({
                title,
                textNoFilled: false,
            });
        }
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        setTimeout(() => { this.validate(); }, 1200);
    };

    handleMandatory = (checkedValues) => {
        this.setState({ mandatory: checkedValues.target.checked });
        setTimeout(() => { this.validate(); }, 200);
    };

    validate = () => {
        const { mandatory, longText, title } = this.state;
        const tit = title && title.length > 3 && true;
        if (tit) {
            this.setState({ textNoFilled: false });
            this.props.handleSaveElement(this.props.element, this.state);
            return;
        }
        this.setState({ textNoFilled: true });
    }

    render() {
        const {
            element, activity,
        } = this.props;
        const { textNoFilled } = this.state;


        const empty = !textNoFilled && this.state.title ? 'white' : '#f7a6b5';
        return (
            <Collapse style={{ backgroundColor: empty }}>
                <Collapse.Panel
                    header={this.state.title ? this.state.title : 'Request GPS Point '}
                    key={element.element_id}
                    extra={
                        (
                            <ButtonsComponent
                                color="primary"
                                iconame="global"
                                titlemod="Ask for GPS point"
                                activity={activity}
                                enableSave={textNoFilled}
                                elementData={element}
                                elementState={this.state} />
                        )
                    }
                >
                    <Typography.Title style={PanelHeader}>
                        Title for GPS point
                    </Typography.Title>

                    <Input maxLength={50} placeholder={this.state.title} value={this.state.title} onChange={this.handleText} />
                    <Checkbox
                        checked={this.state.mandatory}
                        onChange={this.handleMandatory}
                        style={{ color: 'red' }}
                    >
                    Required to submit ?
                    </Checkbox>
                </Collapse.Panel>
            </Collapse>
        );
    }
}

GPS.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(GPS);
