const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
    login: (username, password) => ({
        type: actions.LOGIN_REQUEST,
        user: username,
        pass: password,
    }),
    logout: () => ({
        type: actions.LOGOUT,
    }),
    loginerror: () => ({
        type: actions.LOGIN_ERROR,
    }),
};
export default actions;
