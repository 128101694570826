import { CompleteActivity } from './reducer';



const CompleteActivityActions = {
    LOAD_CompleteActivity: 'LOAD_CompleteActivity',
    CompleteActivity_LOADED: 'CompleteActivity_LOADED',
    ADD_CompleteActivity: 'ADD_CompleteActivity',
    EDIT_CompleteActivity: 'EDIT_CompleteActivity',
    DELETE__CompleteActivity: 'DELETE__CompleteActivity',
    CHANGE_CompleteActivity: 'CHANGE_CompleteActivity',
    EDIT_VIEW: 'EDIT_VIEW',

    changeCompleteActivity: id => ({
        type: CompleteActivityActions.CHANGE_CompleteActivity,
        id,
    }),


    LoadCompleteActivity: (id, userid) => (dispatch) => {
        dispatch({
            type: CompleteActivityActions.LOAD_CompleteActivity,
            CompleteActivityid: id,
            userid,
            CompleteActivity,
        });
    },

    CompleteActivityLoaded: () => ({
        type: CompleteActivityActions.CompleteActivity_LOADED,
        CompleteActivity,

    }),
};
export default CompleteActivityActions;
