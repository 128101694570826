import Auth from './auth/reducer';
import App from './app/reducer';
import Notes from './notes/reducer';
import Todos from './todos/reducer';
import Contacts from './contacts/reducer';
import Chat from './chat/reducers';
import DynamicChartComponent from './dynamicEchart/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import YoutubeSearch from './youtubeSearch/reducers';
import DevReducers from '../customApp/redux/reducers';
import Articles from './articles/reducers';
import Investors from './investors/reducers';
import Activities from './activities/reducer';
import Offers from './offers/reducer';
import Templates from './templates/reducer';
import SingleTemplate from './SingleTemplate/reducer';
import Elements from './elements/reducer';
import CompleteActivities from './registrations/reducer';
import RegSingle from './CompleteRegistration/reducer';

export default {
    Auth,
    App,
    Activities,
    ThemeSwitcher,
    LanguageSwitcher,
    Notes,
    Todos,
    Contacts,
    Chat,
    DynamicChartComponent,
    YoutubeSearch,
    Articles,
    Investors,
    Templates,
    Elements,
    SingleTemplate,
    CompleteActivities,
    RegSingle,
    Offers,
    ...DevReducers,
};
