/* eslint-disable func-names */
import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import ElementsActions from './actions';
import loginerror from '../auth/actions';
import { ergoBe } from '../../settings/index';
import { putDF } from '../../network/request';


export function* PutElements() {
    yield takeEvery(ElementsActions.UPDATE_Elements, function* (data) {
        const ElementsUrl = `${ergoBe.beUrl}ergoprop/_table/elements`;
        const response = yield putDF(ElementsUrl, data);
        if (response.resource) {
            yield put({
                type: ElementsActions.Elements_UPDATED,
                Elements: response.resource,
            });
        } else {
            yield loginerror();
        }
    });
}


export default function* rootSaga() {
    yield all([fork(PutElements)]);
}
