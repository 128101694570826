import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import contactSagas from './contacts/saga';
import templatesSagas from './templates/saga';
import SingleTemplateSagas from './SingleTemplate/saga';
import notesSagas from './notes/saga';
import todosSagas from './todos/saga';
import chatSagas from './chat/sagas';
import youtubeSearchSagas from './youtubeSearch/sagas';
import devSagas from '../customApp/redux/sagas';
import articles from './articles/sagas';
import investors from './investors/sagas';
import ElementsSagas from './elements/saga';
import activities from './activities/saga';
import offers from './offers/saga';
import completeActivities from './registrations/saga';
import RegSingle from './CompleteRegistration/saga';


export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        contactSagas(),
        SingleTemplateSagas(),
        ElementsSagas(),
        templatesSagas(),
        notesSagas(),
        todosSagas(),
        chatSagas(),
        youtubeSearchSagas(),
        devSagas(),
        articles(),
        investors(),
        activities(),
        offers(),
        completeActivities(),
        RegSingle(),
    ]);
}
