/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import {
    object, func, bool,
} from 'prop-types';
import ElementsActions from '../../../../redux/elements/actions';
import { ergoBe } from '../../../../settings/index';
import { makeHeadersDelete } from '../../../../network/request';
import SingleTemplateAction from '../../../../redux/SingleTemplate/actions';

const ButtonWrapper = styled.div`
  display: block;
  position:relative;
  bottom:5px;
`;

const {
    UpdateElements,
    DeleteElements,
} = ElementsActions;

const {
    LoadSingleTemplate,
} = SingleTemplateAction;


class ButtonsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: '',
        };
    }

    componentDidMount() {
        //  let intervalId = setInterval(this.handleSave, 50000);
        // store intervalId in the state so it can be accessed later:
        // this.setState({ intervalId });
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        //      clearInterval(this.state.intervalId);
    }


    handleSave = () => {
        const { elementData, elementState } = this.props;
        const request = {
            resource: [
                {
                    element_config: JSON.stringify(elementState),
                    element_id: elementData.element_id,
                    element_title: elementState.title,
                    element_type: elementData.element_type,
                    element_value: '',
                    instruction: elementData.instruction,
                    mandatory: elementState.mandatory,
                    module_id: elementData.module_id,
                    text: '',
                },
            ],
        };
        this.props.UpdateElements(request);
        this.props.LoadSingleTemplate(this.props.activity.activityTid);
        const save = this.props.stopSave && this.props.stopSave();
    };

    handleDelete = (moduleid) => {
        const ElementsUrl = `${ergoBe.beUrl}ergoprop/_table/elements/${moduleid}?id_field=module_id`;
        const ModuleUrl = `${ergoBe.beUrl}ergoprop/_table/modules/${moduleid}`;

        fetch(ElementsUrl, makeHeadersDelete())
            .then(response => response.json())
            .then((myJson) => {
                console.log(JSON.stringify(myJson));

                fetch(ModuleUrl, makeHeadersDelete())
                    .then(response => response.json())
                    .then((myJson1) => {
                        console.log(JSON.stringify(myJson1));
                        this.props.LoadSingleTemplate(this.props.activity.activityTid);
                    })
                    .catch(error => console.error('Error:', error));
            })
            .catch(error => console.error('Error:', error));
    };

    render() {
        const {
            enableSave, elementData, iconame, titlemod, color, handleSave,
        } = this.props;
        const moduleid = elementData.module_id;
        // const fireSave = handleSave && this.handleSave();
        return (
            <ButtonWrapper>

                <Tooltip placement="top" title="Delete">
                    <Button
                        type="danger"
                        onClick={(event) => {
                            // If you don't want click extra trigger collapse, you can prevent this:
                            event.stopPropagation();
                            this.handleDelete(moduleid);
                        }}
                        shape="circle"
                        icon="delete" />
                </Tooltip>
            </ButtonWrapper>
        );
    }
}

// <Tooltip placement="top" title="Save">
// <Button
//     style={{ marginRight: 5 }}
//     disabled={enableSave}
//     onClick={(event) => {
//         event.stopPropagation();
//         this.handleSave();
//     }}
//     shape="circle"
//     icon="save" />
// </Tooltip>


ButtonsComponent.propTypes = {
    elementData: object.isRequired,
    elementState: object.isRequired,
    UpdateElements: func.isRequired,
    LoadSingleTemplate: func.isRequired,
    enableSave: bool.isRequired,
    activity: object.isRequired,

};

function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default connect(mapStateToProps, {
    UpdateElements,
    DeleteElements,
    LoadSingleTemplate,
})(ButtonsComponent);
