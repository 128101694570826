import React, { Component } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import '../mobileCont.css';
import {
    InputNumber,
} from 'antd';
import SingleTemplateAction from '../../../../redux/SingleTemplate/actions';

import IntlMessages from '../../../../components/utility/intlMessages';


const {
    LoadSingleTemplate,
} = SingleTemplateAction;

class SelectDuration extends Component {
    render() {
        const { onDaysChange, onHoursChange, onMinutesChange } = this.props;
        return (
            <div>
                <div className="isoHelperText">
                    <IntlMessages id="WorkViewr.index.selectDuration" />
                </div>
                <IntlMessages id="WorkViewr.index.selectDays" />
                <div className="isoInputWrapper">
                    <InputNumber
                        id="days"
                        min={0}
                        max={365}
                        onChange={onDaysChange}
                        defaultValue={0} />
                </div>
                <IntlMessages id="WorkViewr.index.selectHours" />
                <div className="isoInputWrapper">
                    <InputNumber
                        id="hours"
                        min={0}
                        max={23}
                        onChange={onHoursChange}
                        defaultValue={0} />
                </div>
                <IntlMessages id="WorkViewr.index.selectMins" />
                <div className="isoInputWrapper">
                    <InputNumber
                        id="mins"
                        min={0}
                        max={59}
                        onChange={onMinutesChange}
                        defaultValue={0} />
                </div>
            </div>

        );
    }
}

SelectDuration.propTypes = {
    onDaysChange: object.isRequired,
    onHoursChange: object.isRequired,
    onMinutesChange: object.isRequired,
};

function mapStateToProps(state) {
    return {
        ...state,
        isLoading: state.SingleTemplate.TempisLoading,
    };
}

export default connect(mapStateToProps, {
    LoadSingleTemplate,
})(SelectDuration);
