import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import CompleteActivityActions from './actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';

export function* addCompleteActivity() {
    yield takeEvery(CompleteActivityActions.ADD_CompleteActivity, function* () {});
}
export function* editCompleteActivity() {
    yield takeEvery(CompleteActivityActions.EDIT_CompleteActivity, function* () {});
}
export function* deleteCompleteActivity() {
    yield takeEvery(CompleteActivityActions.DELETE__CompleteActivity, function* () {});
}


export function* LoadCompleteActivity() {
    yield takeEvery(CompleteActivityActions.LOAD_CompleteActivity, function* (data) {
        console.log(data);
        const { userid } = data;
        const url = ergoBe.beUrl+'ergoprop/_table/activitiesforms?filter=(status=COMPLETED)%20and%20(owner_userid='+ userid +')&include_count=true';
        const response = yield callDF(url);
        if (response) {
            yield put({
                type: CompleteActivityActions.CompleteActivity_LOADED,
                CompleteActivity: response.resource,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(LoadCompleteActivity)]);
}
