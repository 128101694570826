import React, { Component } from 'react';
import { bool, object, func } from 'prop-types';
import {
    Typography, Spin, Button, Avatar, Icon,
} from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Box from '../../../components/utility/box';
import ToolBox from '../../components/Toolbox';
import GuideImage from './Modules/Guides/GuideImage';
import GPS from './Modules/Questions/gps';
import GuideMap from './Modules/Guides/GuideMap';
import GuideText from './Modules/Guides/GuideText';
import GuideAddress from './Modules/Guides/GuideAddress';
import SinglePreview from './Modules/SinglePreview';
import ElementsActions from '../../../redux/elements/actions';
import SingleTemplateAction from '../../../redux/SingleTemplate/actions';
import theme from '../../../settings/themes/themedefault';
import { ergoBe } from '../../../settings/index';
import { makeHeadersPost, makeHeadersPut, imgAuth } from '../../../network/request';
import YesNo from './Modules/Questions/yesno';
import Photo from './Modules/Questions/photo';
import LongText from './Modules/Questions/longtext';
import Nummeric from './Modules/data/number';
import Signature from './Modules/Questions/signature';
import Measure from './Modules/data/measure';


const { Paragraph } = Typography;
const { Text } = Typography;


const {
    LoadSingleTemplate,
} = SingleTemplateAction;

const {

    UpdateElements,
} = ElementsActions;

const FormBuilderHeader = {
    fontWeight: '600',
    fontSize: 21,
    textAlign: 'center',
    fontFamily: theme.fonts.primary,
};

const CardsWrapper = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;

`;

const ToolColum = styled.div`
  padding: 10px 10px ;
  flex-flow: col wrap;
  display: flex;
  flex-direction:  column;
  justify-content: center;
`;

const FormContainer = styled.div`
  padding: 10px 10px ;
  display: flex;
  flex-flow: col wrap;
  flex-direction:  column;
  overflow: hidden;
  border-radius: 1%;
  min-heigth: 100%;  
`;

const grid = 1;

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',
    // styles we need to apply on draggables
    ...draggableStyle,
});


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    console.log(removed);
    result.splice(endIndex, 0, removed);
    return result;
};


class Workeditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: props.activity,
            isLoading: props.isLoading,
            isLoadingTool: false,
            modules: [],
            visible: false,
            selectedElement: {},
            Modsvalidated: true,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }


    componentDidMount() {
        this.setState({ isLoading: true });
        const activityid = this.props.history.location.state.activityTid;
        this.props.LoadSingleTemplate(activityid);
    }


    componentWillReceiveProps(next) {
        if (next.activity) {
            this.setState({ isLoadingTool: true });
            const sorted = next.activity.modules.sort((a, b) => a.order - b.order);
            this.setState({ modules: sorted });
            this.setState({ activity: next.activity });
            this.setState({ isLoading: false });
            this.setState({ isLoadingTool: false });
        }
    }


    onDragEnd(result) {
        this.setState({ isLoadingTool: true });
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const modules = reorder(
            this.state.modules,
            result.source.index,
            result.destination.index,
        );

        modules.map((item, i) => this.handleSaveOrder(item, i));
        this.setState({
            modules,
        });
        this.setState({ isLoadingTool: false });
    //  this.props.LoadSingleTemplate(this.props.activity.activityTid);
    }


    handleSaveElement = (elementData, elementState) => {
        const request = {
            resource: [
                {
                    element_config: JSON.stringify(elementState),
                    element_id: elementData.element_id,
                    element_title: elementState.title,
                    element_type: elementData.element_type,
                    element_value: '',
                    instruction: elementData.instruction,
                    mandatory: elementState.mandatory,
                    module_id: elementData.module_id,
                    text: '',
                },
            ],
        };
        this.props.UpdateElements(request);
        this.props.LoadSingleTemplate(this.props.activity.activityTid);
        this.setState({ Modsvalidated: true });
        //  const save = this.props.stopSave && this.props.stopSave();
    };


    convertTowork = (activity) => {
        this.props.history.push('WorkPublisher', { activity });
    };

    handleSaveOrder = (module, order) => {
        const url2 = `ergoprop/_table/modules/${module.module_id}`;
        const mak = {
            order,
            modElements: [],
        };
        console.log(mak);
        console.log(module.module_id);
        fetch(ergoBe.beUrl + url2, makeHeadersPut(mak))
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then((myJson1) => {
                console.log(myJson1);
            })
            .catch(error => console.error('Error:', error));
    };


    updateTitle = (e) => {
        const activityid = this.props.history.location.state.activityTid;
        const mak = {
            name: e,
        };
        const url2 = `ergoprop/_table/activitiesTemplates/${activityid}`;

        fetch(ergoBe.beUrl + url2, makeHeadersPut(mak))
            .then(response => response.json())
            .then((myJson1) => {
                console.log(JSON.stringify(myJson1));
                this.props.LoadSingleTemplate(this.props.activity.activityTid);
                this.setState({ activity: this.props.activity });
            })
            .catch(error => console.error('Error:', error));
    };


   AddModules = (type) => {
       this.setState({ isLoadingTool: true });
       const moduleRequest = {
           resource: [
               {
                   name: type,
                   activityTemplateId: this.state.activity.activityTid,
                   order: -1,
               },
           ],
       };
       const ElementUrl = `${ergoBe.beUrl}ergoprop/_table/elements?fields=*`;
       const ModuleUrl = `${ergoBe.beUrl}ergoprop/_table/modules/`;

       fetch(ModuleUrl, makeHeadersPost(moduleRequest))
           .then(response => response.json())
           .then((myJson) => {
               const modid = myJson.resource[0].module_id;
               const elementRequest = {
                   resource: [
                       {
                           chooser: 'chooser',
                           text: 'ComboBox',
                           module_id: modid,
                           element_type: type,
                           element_config: null,
                           element_title: null,
                           element_value: null,
                           mandatory: false,
                           instruction: 'instructions',
                           config: null,
                           activityTemplateId: this.state.activity.activityTid,
                       },
                   ],
               };

               fetch(ElementUrl, makeHeadersPost(elementRequest))
                   .then(response => response.json())
                   .then((myJson1) => {
                       console.log(JSON.stringify(myJson1));
                       this.props.LoadSingleTemplate(this.props.activity.activityTid);
                       this.setState({ activity: this.props.activity });
                       this.setState({ isLoadingTool: false });
                   })
                   .catch(error => console.error('Error:', error));
           })
           .catch(error => console.error('Error:', error));
   };

   render() {
       // Component gets from navigation the activity object and renders the editor
       const { activity, isLoading, isLoadingTool } = this.state;
       const { modules, Modsvalidated } = this.state;
       const { classes } = this.props;

       return (
           <CardsWrapper>
               <ToolColum style={FormBuilderHeader}>
                   <ToolBox
                       AddModule={this.AddModules}
                       isLoadingTool={isLoadingTool} />
               </ToolColum>

               <FormContainer>
                   {isLoading && <Spin /> }
                   {!isLoading && activity && (
                       <Box>
                           <div style={{ maxHeight: 70, display: 'flex' }}>
                               <div style={{ marginRight: 10 }}>
                                   <Avatar src={activity.image + imgAuth()} size={38} />
                               </div>
                               <div>
                                   <Paragraph style={FormBuilderHeader} editable={{ onChange: this.updateTitle }}>
                                       {activity.name}
                                   </Paragraph>
                               </div>
                               <div>
                                   {Modsvalidated
                                       && (
                                           <Button style={{ marginLeft: 10 }} onClick={() => this.convertTowork(activity)}>
                            Ready to Send
                                               <Icon type="right" />
                                           </Button>
                                       )
                                   }
                                   {isLoadingTool && <Spin /> }
                               </div>
                           </div>
                           <DragDropContext onDragEnd={this.onDragEnd}>
                               <Droppable droppableId="droppable">
                                   {(provided, snapshot) => (
                                       <div
                                           {...provided.droppableProps}
                                           ref={provided.innerRef}
                                           style={getListStyle(snapshot.isDraggingOver)}
                                       >
                                           {modules.map((row, index) => (
                                               <Draggable
                                                   key={String(row.module_id)}
                                                   draggableId={String(row.module_id)}
                                                   index={index}
                                               >
                                                   {(provided, snapshot) => (
                                                       <div
                                                           ref={provided.innerRef}
                                                           {...provided.draggableProps}
                                                           {...provided.dragHandleProps}
                                                           key={row.module_id}
                                                           style={getItemStyle(
                                                               snapshot.isDragging,
                                                               provided.draggableProps.style,
                                                           )}
                                                       >
                                                           {row.modElements[0].element_type === 'Measure' && <Measure handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'Number' && <Nummeric handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'LongText' && <LongText handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'Photo' && <Photo handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'Yes/No' && <YesNo handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'GPS' && <GPS handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'Signature' && <Signature handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'GuideImage' && <GuideImage handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'GuideText' && <GuideText handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'GuideAddress' && <GuideAddress handleSaveElement={this.handleSaveElement} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                           {row.modElements[0].element_type === 'GuideMap' && <GuideMap handleSaveElement={this.handleSaveElement} openMapModal={this.openMapModal} classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} style={{ minWidth: '100%' }} /> }
                                                       </div>
                                                   )}
                                               </Draggable>
                                           ))
                                           }
                                       </div>

                                   )}
                               </Droppable>
                           </DragDropContext>
                       </Box>
                   )}
               </FormContainer>
               {!isLoading && activity && (
                   <FormContainer>
                       <Box>
                           <div style={{ maxHeight: 70, display: 'flex' }}>
                               <div style={FormBuilderHeader}>
                                   <Paragraph>
                                      Quick preview
                                   </Paragraph>
                               </div>
                           </div>
                           <div className="smartphone">
                               <div className="smartphone.content">
                                   <Text style={FormBuilderHeader} disabled>{activity.name}</Text>
                                   {modules.map(row => (
                                       <div key={row.module_id}>
                                           {row.modElements[0].element_type && <SinglePreview classes={classes} activity={activity} onModuleDelete={this.handleDeleteModule} element={row.modElements[0]} /> }
                                       </div>
                                   ))
                                   }
                               </div>
                           </div>
                       </Box>
                   </FormContainer>
               )}
           </CardsWrapper>
       );
   }
}

Workeditor.propTypes = {
    activity: object,
    isLoading: bool.isRequired,

    LoadSingleTemplate: func.isRequired,
    history: object.isRequired,
};

function mapStateToProps(state) {
    return {
        ...state,
        activity: state.SingleTemplate.SingleTemplate[0],
        isLoading: state.SingleTemplate.TempisLoading,
    };
}

export default connect(mapStateToProps, {
    LoadSingleTemplate,
    UpdateElements,
})(Workeditor);
