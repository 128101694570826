import antdGr from 'antd/lib/locale-provider/el_GR';
import appLocaleData from 'react-intl/locale-data/el';
import grMessages from '../locales/el_GR.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const GrLang = {
    messages: {
        ...grMessages,
    },
    antd: antdGr,
    locale: 'el-GR',
    data: appLocaleData,
};
export default GrLang;
