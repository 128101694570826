import TemplatesActions from './actions';

const Templates = [];

const initState = {
    Templates,
    seectedId: [],
    editView: false,
    TempisLoading: false,
};

export default function TemplatesReducer(state = initState, action) {
    switch (action.type) {
    case TemplatesActions.CHANGE_Templates:
        return {
            ...state,
            seectedId: action.id,
            editView: false,
        };
    case TemplatesActions.ADD_Templates:
        return {
            ...state,
            Templates: action.Templates,
            seectedId: action.selectedId,
            editView: true,
        };
    case TemplatesActions.EDIT_Templates:
        return {
            ...state,
            Templates: action.Templates,
        };
    case TemplatesActions.DELETE__Templates:
        return {
            ...state,
            Templates: action.Templates,
            seectedId: action.seectedId,
        };
    case TemplatesActions.LOAD_Templates:
        return {
            ...state,
            TempisLoading: true,
        };
    case TemplatesActions.Templates_LOADED:
        return {
            ...state,
            Templates: action.Templates,
            TempisLoading: false,
        };
    case TemplatesActions.EDIT_VIEW:
        return {
            ...state,
            editView: action.view,
        };
    default:
        return state;
    }
}
export { Templates };
