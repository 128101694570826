import ActivityActions from './actions';

const Activity = [];
const initState = {
    Activity,
    seectedId: [],
    editView: false,
    ActivityisLoading: false,
};

export default function ActivityReducer(state = initState, action) {
    switch (action.type) {
    case ActivityActions.CHANGE_Activity:
        return {
            ...state,
            seectedId: action.id,
            editView: false,
        };
    case ActivityActions.ADD_Activity:
        return {
            ...state,
            Activity: action.Activity,
            seectedId: action.selectedId,
            editView: true,
        };
    case ActivityActions.EDIT_Activity:
        return {
            ...state,
            Activity: action.Activity,
        };
    case ActivityActions.DELETE__Activity:
        return {
            ...state,
            Activity: action.Activity,
            seectedId: action.seectedId,
        };
    case ActivityActions.LOAD_Activity:
        return {
            ...state,
            ActivityisLoading: true,
        };
    case ActivityActions.Activity_LOADED:
        return {
            ...state,
            Activity: action.Activity,
            ActivityisLoading: false,
        };
    case ActivityActions.EDIT_VIEW:
        return {
            ...state,
            editView: action.view,
        };
    default:
        return state;
    }
}
export { Activity };
