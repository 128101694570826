import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import ActivityActions from './actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';

export function* addActivity() {
    yield takeEvery(ActivityActions.ADD_Activity, function* () {});
}
export function* editActivity() {
    yield takeEvery(ActivityActions.EDIT_Activity, function* () {});
}
export function* deleteActivity() {
    yield takeEvery(ActivityActions.DELETE__Activity, function* () {});
}


export function* LoadActivity() {
    yield takeEvery(ActivityActions.LOAD_Activity, function* (data) {

        const { userid, status } = data;
        const url = ergoBe.beUrl+"ergoprop/_table/activitiesforms?filter=(status="+status+")%20and%20(owner_userid="+ userid +")&include_count=true";
        const response = yield callDF(url);
        if (response) {
            yield put({
                type: ActivityActions.Activity_LOADED,
                Activity: response.resource,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(LoadActivity)]);
}
