import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import SingleTemplateActions from './actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';

export function* addSingleTemplate() {
    yield takeEvery(SingleTemplateActions.ADD_SingleTemplate, function* () {});
}
export function* editSingleTemplate() {
    yield takeEvery(SingleTemplateActions.EDIT_SingleTemplate, function* () {});
}
export function* deleteSingleTemplate() {
    yield takeEvery(SingleTemplateActions.DELETE__SingleTemplate, function* () {});
}

export function* LoadSingleTemplate() {
    yield takeEvery(SingleTemplateActions.LOAD_SingleTemplate, function* (data) {
        const actid = data.Temmplateid;
        const urls = `ergoprop/_table/activitiestemplates?filter=(status=TEMPLATE)and(wkchildid=251)and(activityTid=${ actid })`;
        const compUsers = ergoBe.beUrl + urls;

        const response = yield callDF(compUsers);
        if (response) {
            yield put({
                type: SingleTemplateActions.SingleTemplate_LOADED,
                SingleTemplate: response.resource,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(LoadSingleTemplate)]);
}
