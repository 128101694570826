import getDevSidebar from '../../customApp/sidebar';

const options = [
    {
        key: 'Templates_list',
        label: 'sidebar.templates',
        leftIcon: 'ion-android-archive',

    },
    {
        key: 'UserActivities',
        label: 'sidebar.worksassigned',
        leftIcon: 'ion-map',
        children: [
            {
                key: 'UserActivities',
                label: 'sidebar.worksassigned.pending',
                leftIcon: 'ion-android-checkbox-outline',
            },
            {
                key: 'Templates_Analytics',
                label: 'sidebar.templates.analytics',
                leftIcon: 'ion-ribbon-b',
            },

        ],
    },

    {
        key: 'Reports',
        label: 'Reports',
        leftIcon: 'ion-refresh',
        children: [
            {
                key: 'registrations',
                label: 'Registrations',
                leftIcon: 'ion-refresh',
            },
            {
                key: 'Templates_Analytics',
                label: 'Analytics',
                leftIcon: 'ion-ribbon-b',
            },

        ],
    },
    

    {
        key: 'ergohubmap',
        label: 'sidebar.PublicNetwork',
        leftIcon: 'ion-ios-body',
        children: [
            {
                key: 'UserOffers',
                label: 'sidebar.offers',
                leftIcon: 'ion-android-checkbox-outline',
            },
            {
                key: 'ergohublist',
                label: 'sidebar.ergohublist',
                leftIcon: 'ion-android-checkbox-outline',
            },
     
        ],
    },

    {
        key: 'Private Network',
        label: 'sidebar.privatenetwork',
        leftIcon: 'ion-help-circled',
        children: [
            {
                key: 'RegisteredUsers',
                label: 'sidebar.executors',
                leftIcon: 'ion-android-checkbox-outline',
            },
            {
                key: 'InviteUser',
                label: 'Invite User',
                leftIcon: 'ion-android-checkbox-outline',
            },
     
        ],
    },

    {
        key: 'Settings',
        label: 'sidebar.settings',
        leftIcon: 'ion-android-cloud-circle',
        children: [

            {
                key: 'UserProfile',
                label: 'sidebar.userprofile',
                leftIcon: 'ion-android-checkbox-outline',
            },
            {
                key: 'forgotpassword',
                label: 'sidebar.forgotPw',
                withoutDashboard: true,
            },
            {
                key: 'resetpassword',
                label: 'sidebar.resetPw',
                withoutDashboard: true,
            },
        ],

    },
    ...getDevSidebar,
];
export default options;
