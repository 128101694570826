import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleConfig } from '../../settings/index';

const AnyReactComponent = ({ text }) => <div style={{ backgroundColor: 'red', minHeight: 10, minWidth: 10 }}>{text}</div>;

class ReadOnlyMap extends Component {
    constructor(props) {
        super(props);

        const startPoint = {
            center: {
                lat: this.props.lat,
                lng: this.props.lng,
            },
            zoom: this.props.zoom,
        };

        this.state = {
            startPoint,
        };
    }

    componentWillReceiveProps(next) {
        const startPoint = {
            center: {
                lat: next.lat,
                lng: next.lng,
            },
            zoom: next.zoom,
        };
        this.setState({ startPoint });
    }


    // renderMarkers = (map, maps) => {
    //     const marker = new maps.Marker({
    //         position: { lat: this.props.lat, lng: this.props.lng },
    //         map,
    //         title: 'Hello World!',
    //     });
    //     return marker;
    // };


    createMapOptions= maps => ({
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
    });

    render() {
        const { startPoint } = this.state;

        return (
        // Important! Always set the container height explicitly
            <div style={{ height: this.props.height, width: this.props.width }}>
                <GoogleMapReact
                    options={this.createMapOptions}
                    bootstrapURLKeys={{ key: googleConfig.apiKey }}
                    // onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    defaultCenter={startPoint.center}
                    defaultZoom={startPoint.zoom}
                    center={startPoint.center}
                    zoom={startPoint.zoom}
                >
                    <AnyReactComponent
                        lat={this.props.lat}
                        lng={this.props.lng}
                        text="ErgoPoint" />
                </GoogleMapReact>
            </div>
        );
    }
}

export default ReadOnlyMap;
