import RegSingleActions from './actions';

const RegSingle = [];

const initState = {
    RegSingle,
    selectedId: [],
    editView: false,
    RegisLoading: false,
};

export default function RegSingleReducer(state = initState, action) {
    switch (action.type) {


    case RegSingleActions.LOAD_RegSingle:
        return {
            ...state,
            RegisLoading: true,
        };
    case RegSingleActions.RegSingle_LOADED:
        return {
            ...state,
            RegSingle: action.RegSingle,
            RegisLoading: false,
        };
    default:
        return state;
    }
}
export { RegSingle };
