import actions from './actions';

const initState = {
    idToken: null,
    userLogged: false,
    authisLoading: false,
    ergoUser: {},
    dfUser: {},
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
    case actions.LOGIN_SUCCESS:
        return {
            idToken: action.token,
            ergoUser: action.ergoUser,
            dfUser: action.dfUser,
            authisLoading: false,
            userLogged: true,

        };

        case actions.LOGIN_ERROR:
            return initState;

    case actions.LOGIN_REQUEST:
        return {
            authisLoading: true,
        };

    case actions.LOGOUT:
        return initState;
    default:
        return state;
    }
}
