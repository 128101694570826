import SingleTemplateActions from './actions';

const SingleTemplate = [];

const initState = {
    SingleTemplate,
    seectedId: [],
    editView: false,
    TempisLoading: false,
};

export default function SingleTemplateReducer(state = initState, action) {
    switch (action.type) {
    case SingleTemplateActions.CHANGE_SingleTemplate:
        return {
            ...state,
            seectedId: action.id,
            editView: false,
        };
    case SingleTemplateActions.ADD_SingleTemplate:
        return {
            ...state,
            SingleTemplate: action.SingleTemplate,
            seectedId: action.selectedId,
            editView: true,
        };
    case SingleTemplateActions.EDIT_SingleTemplate:
        return {
            ...state,
            SingleTemplate: action.SingleTemplate,
        };
    case SingleTemplateActions.DELETE__SingleTemplate:
        return {
            ...state,
            SingleTemplate: action.SingleTemplate,
            seectedId: action.seectedId,
        };
    case SingleTemplateActions.LOAD_SingleTemplate:
        return {
            ...state,
            TempisLoading: true,
        };
    case SingleTemplateActions.SingleTemplate_LOADED:
        return {
            ...state,
            SingleTemplate: action.SingleTemplate,
            TempisLoading: false,
        };
    case SingleTemplateActions.EDIT_VIEW:
        return {
            ...state,
            editView: action.view,
        };
    default:
        return state;
    }
}
export { SingleTemplate };
