import React, { Component } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import '../mobileCont.css';
import {
    Select,
} from 'antd';
import SingleTemplateAction from '../../../../redux/SingleTemplate/actions';
import IntlMessages from '../../../../components/utility/intlMessages';

const { Option } = Select;

const {
    LoadSingleTemplate,
} = SingleTemplateAction;

class SelectUser extends Component {
    render() {
        const {
            onChange, onFocus, onBlur, onSearch, Contacts,
        } = this.props;
        const users = Contacts.contacts;
        return (
            <div>
                <div className="isoHelperText">
                    <IntlMessages id="WorkViewr.index.selectUser" />
                </div>
                <div className="isoInputWrapper">
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder={<IntlMessages id="WorkViewr.index.selectPerson" />}
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {users.map(row => (
                            <Option key={row.UsersDetails.user_id} value={row.UsersDetails.user_id}>{row.UsersDetails.email}</Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }
}

SelectUser.propTypes = {
    onChange: object.isRequired,
    onFocus: object.isRequired,
    onBlur: object.isRequired,
    onSearch: object.isRequired,
    Contacts: object.isRequired,
};

function mapStateToProps(state) {
    return {
        ...state,
        isLoading: state.SingleTemplate.TempisLoading,
    };
}

export default connect(mapStateToProps, {
    LoadSingleTemplate,
})(SelectUser);
