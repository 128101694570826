import { RegSingle } from './reducer';

function ascendingSort(RegSingle1, RegSingle2) {
    const name1 = RegSingle1.name ? RegSingle1.name.toUpperCase() : '~';
    const name2 = RegSingle2.name ? RegSingle2.name.toUpperCase() : '~';
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const RegSingleActions = {
    LOAD_RegSingle: 'LOAD_RegSingle',
    RegSingle_LOADED: 'RegSingle_LOADED',
    ADD_RegSingle: 'ADD_RegSingle',
    EDIT_RegSingle: 'EDIT_RegSingle',
    DELETE__RegSingle: 'DELETE__RegSingle',
    CHANGE_RegSingle: 'CHANGE_RegSingle',
    EDIT_VIEW: 'EDIT_VIEW',

    changeRegSingle: id => ({
        type: RegSingleActions.CHANGE_RegSingle,
        id,
    }),
    
    addRegSingle: () => {
        const newRegSingle = {
            id: new Date(),
            firstName: '',
            avatar: RegSingle[new Date() % 10].avatar,
            LastName: '',
            mobile: '',
            home: '',
            name: '',
            company: '',
            work: '',
            note: '',

        };
        return (dispatch, getState) => {
            dispatch({
                type: RegSingleActions.ADD_RegSingle,
                RegSingle: [...getState().RegSingle.RegSingle, newRegSingle],
                selectedId: newRegSingle.id,
            });
        };
    },
    
    editRegSingle: newRegSingle => (dispatch, getState) => {
        const RegSingle = getState().RegSingle.RegSingle;
        const newRegSingle = [];
        RegSingle.forEach((RegSingle) => {
            if (RegSingle.id === newRegSingle.id) {
                newRegSingle.push(newRegSingle);
            } else {
                newRegSingle.push(RegSingle);
            }
        });
        dispatch({
            type: RegSingleActions.EDIT_RegSingle,
            RegSingle: newRegSingle.sort(ascendingSort),
        });
    },
    
    deleteRegSingle: id => (dispatch, getState) => {
        const RegSingle = getState().RegSingle.RegSingle;
        const seectedId = getState().RegSingle.seectedId;
        const newRegSingle = [];
        RegSingle.forEach((RegSingle) => {
            if (RegSingle.id === id) {
            } else {
                newRegSingle.push(RegSingle);
            }
        });
        dispatch({
            type: RegSingleActions.DELETE__RegSingle,
            RegSingle: newRegSingle,
            seectedId: id === seectedId ? undefined : seectedId,
        });
    },

    viewChange: view => ({
        type: RegSingleActions.EDIT_VIEW,
        view,
    }),

    LoadRegSingle: id => (dispatch) => {
        dispatch({
            type: RegSingleActions.LOAD_RegSingle,
            userid: id,
            RegSingle,
        });
    },
    RegSingleLoaded: () => ({
        type: RegSingleActions.RegSingle_LOADED,
        RegSingle,

    }),
};
export default RegSingleActions;
