import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import './mobileCont.css';
import {
    Button, Input, Col, Form, Select, Checkbox, DatePicker, Icon,
} from 'antd';
import SingleTemplateAction from '../../../redux/SingleTemplate/actions';
import IntlMessages from '../../../components/utility/intlMessages';
import NoAPIKey from '../../../components/utility/noApiKey';
import { googleConfig } from '../../../settings/index';
import SimpleMap from '../ErgoHub/basicmap';
import PageHeader from '../../../components/utility/pageHeader';

const {
    LoadSingleTemplate,
} = SingleTemplateAction;

const { TextArea } = Input;
const { Option } = Select;

class PublicForm extends React.Component {
    constructor(props) {
        super(props);
        // const sorted = props.activity.sort((a, b) => a.order > b.order);
        this.state = {
            location: props.selectedLocation,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }
            const values = {
                ...fieldsValue,
                ActivityForm: this.props.activity,
                startdatefrom: fieldsValue.startdatefrom.format('YYYY-MM-DD HH:mm:ss'),
                startdatedue: fieldsValue.startdatedue.format('YYYY-MM-DD HH:mm:ss'),
                location: this.state.location,
            };
            this.props.publishHub(values);
        });
    };

    setLocation = (value) => {
        this.setState({ location: value });
        console.log(this.state);
    };

    render() {
        const { disabled, onCanchel, onBack } = this.props;
        const { getFieldDecorator } = this.props.form;
        const config = {
            rules: [{ type: 'object', required: true, message: 'Please select time!' }],
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 5,
                },
                sm: {
                    span: 16,
                    offset: 5,
                },
            },
        };

        return (
            <div>
                <PageHeader>
                Location Coordinates
                    lat:
                    {this.state.location.lat}
                      lng:
                    {this.state.location.lng}
                </PageHeader>

                <Form {...tailFormItemLayout} onSubmit={this.handleSubmit} className="login-form">
                    <Col span={12}>
                        <Form.Item label="Category ">
                            {getFieldDecorator('category', {
                                valuePropName: 'category',
                            })(
                                <Select
                                    style={{ width: 150 }}
                                    placeholder="Category"
                                >
                                    <Option value="86">Maintanance</Option>
                                    <Option value="87">Home Renovation</Option>
                                    <Option value="88">Diy Help</Option>
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Start Date from ">
                            {getFieldDecorator('startdatefrom', config)(
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />,
                            )}
                        </Form.Item>


                        <Form.Item {...tailFormItemLayout} label="Start Date Due ">
                            {getFieldDecorator('startdatedue', config)(
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />,
                            )}
                        </Form.Item>


                        <Col>

                            <Form.Item {...tailFormItemLayout}>
                                {getFieldDecorator('productsOffers', {
                                    valuePropName: 'productsOffers',
                                })(
                                    <Checkbox><IntlMessages id="WorkViewr.index.productsOffers" /></Checkbox>,
                                )}
                            </Form.Item>


                            <Form.Item {...tailFormItemLayout}>
                                {getFieldDecorator('visErgolabos', {
                                    valuePropName: 'visErgolabos',
                                })(
                                    <Checkbox><IntlMessages id="WorkViewr.index.visabilityErgolabos" /></Checkbox>,
                                )}
                            </Form.Item>


                            <Form.Item {...tailFormItemLayout}>
                                {getFieldDecorator('visSuppliers', {
                                    valuePropName: 'visSuppliers',
                                })(
                                    <Checkbox><IntlMessages id="WorkViewr.index.visablitySuppliers" /></Checkbox>,
                                )}
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                {getFieldDecorator('visabilityErgolabous', {
                                    valuePropName: 'visabilityErgolabous',
                                })(
                                    <Checkbox><IntlMessages id="WorkViewr.index.visabilityErgolabos" /></Checkbox>,
                                )}
                            </Form.Item>
                        </Col>


                        <Form.Item {...tailFormItemLayout}>
                            {getFieldDecorator('anonymization', {
                                valuePropName: 'anonymization',
                            })(
                                <Checkbox><IntlMessages id="WorkViewr.index.anonymization" /></Checkbox>,
                            )}
                        </Form.Item>


                        <Form.Item label="Extra usfefull comments ">
                            {getFieldDecorator('comments')(
                                <TextArea
                                    placeholder="Comments"
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    style={{ marginBottom: '0.5em' }} />,
                            )}
                        </Form.Item>

                        <div>
                            <span className="button">
                                <Button disabled={disabled} htmlType="submit">
                                    <IntlMessages id="WorkViewr.index.assignWork" />
                                </Button>
                            </span>
                            <span className="button">
                                <Button type="" onClick={onCanchel}>
                                    <IntlMessages id="WorkViewr.index.cancel" />
                                </Button>

                                <Button
                                    onClick={onBack}
                                    type="primary"
                                >
                                    <Icon type="left" />
                            Backward
                                </Button>
                            </span>
                        </div>
                    </Col>


                    <Col span={12}>
                        {googleConfig.apiKey ? (
                            <div>
                                <SimpleMap
                                    selectedLocation={this.setLocation}
                                    googleConfig={googleConfig}
                                    mapHeight={300} />


                            </div>

                        ) : (
                            <NoAPIKey />
                        )}
                    </Col>
                </Form>
            </div>

        );
    }
}

PublicForm.propTypes = {
    disabled: object.isRequired,
    publishToHub: func.isRequired,
    onCanchel: func.isRequired,
    activity: object.isRequired,
    Auth: object.isRequired,
    form: object.isRequired,
};

function mapStateToProps(state) {
    return {
        ...state,
        isLoading: state.SingleTemplate.TempisLoading,
    };
}

const WrappedPublicForm = Form.create({ name: 'register' })(PublicForm);

export default connect(mapStateToProps, {
    LoadSingleTemplate,
})(WrappedPublicForm);
