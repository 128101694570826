import { Elements } from './reducer';

const ElementsActions = {
    UPDATE_Elements: 'UPDATE_Elements',
    DElETE_Elements: 'DElETE_Elements',
    Elements_DELETED: 'Elements_DELETED',
    Module_DELETED: 'Module_DELETED',
    Elements_UPDATED: 'Elements_UPDATED',

    UpdateElements: data => (dispatch) => {
        dispatch({
            type: ElementsActions.UPDATE_Elements,
            resource: data.resource,
        });
    },

    DeleteElements: data => (dispatch) => {
        dispatch({
            type: ElementsActions.DElETE_Elements,
            element: data,
        });
    },

    ElementsDeleted: () => ({
        type: ElementsActions.Elements_DELETED,
    }),

    ElementsUpdated: () => ({
        type: ElementsActions.Elements_UPDATED,
        Elements,

    }),
};
export default ElementsActions;
