import React from "react";
import HelperText from "./NoApiKey.style";
import NoApiKeyImg from "../../image/NoAPIKey.svg";
import IntlMessages from '../../components/utility/intlMessages';

export default ({ width = "100%", height = "40vh" }) => (
  <HelperText className="isoHelperText" style={{ width, height }}>
    <img alt="#" src={NoApiKeyImg} />
    <h3>
      {<IntlMessages id="Utility.noApiKey.messageDisplayed" />}
    </h3>
  </HelperText>
);
