import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import ThemeSwitcher from '../ThemeSwitcher';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import { AppLocale } from '../../dashApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import './global.css';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
    componentDidMount() {
        // const userid = this.props.auth.ergoUser.user_id;
        // this.props.LoadContact(userid);
        // this.props.LoadActivity(10, userid, 'PUBLISHED');
        // this.props.LoadContact(userid);
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAF3VL75j1_8VCBtC-dxZFYN07cjcjG5_w&libraries=places';
        // script.async = true;
        document.body.appendChild(script);
    }


    render() {
        const { url } = this.props.match;
        const { locale, selectedTheme, height } = this.props;
        const currentAppLocale = AppLocale[locale];
        const appHeight = window.innerHeight;

        return (
            <ConfigProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <ThemeProvider theme={themes[selectedTheme]}>
                        <AppHolder>
                            <Layout style={{ height: appHeight }}>
                                <Debounce time="1000" handler="onResize">
                                    <WindowResizeListener
                                        onResize={windowSize => this.props.toggleAll(
                                            windowSize.windowWidth,
                                            windowSize.windowHeight,
                                        )
                                        } />
                                </Debounce>
                                <Topbar url={url} />
                                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                                    <Sidebar url={url} />
                                    <Layout
                                        className="isoContentMainLayout"
                                        style={{
                                            height,
                                        }}
                                    >
                                        <Content
                                            className="isomorphicContent"
                                            style={{
                                                padding: '70px 0 0',
                                                flexShrink: '0',
                                                background: '#f1f3f6',
                                                position: 'relative',
                                            }}
                                        >
                                            <AppRouter url={url} />
                                        </Content>
                                        <Footer
                                            style={{
                                                background: '#ffffff',
                                                textAlign: 'center',
                                                borderTop: '1px solid #ededed',
                                            }}
                                        >
                                            {siteConfig.footerText}
                                        </Footer>
                                    </Layout>
                                </Layout>
                                <ThemeSwitcher />
                            </Layout>
                        </AppHolder>
                    </ThemeProvider>
                </IntlProvider>
            </ConfigProvider>
        );
    }
}

export default connect(
    state => ({
        ...state,
        auth: state.Auth,
        locale: state.LanguageSwitcher.language.locale,
        selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
        height: state.App.height,
    }),
    {
        logout, toggleAll,
    },
)(App);
