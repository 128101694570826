

export default {
    apiUrl: 'https://be.ergoprop.com/api/v2/',
};
const ergoBe = {
    beUrl: 'https://be.ergoprop.com/api/v2/',
    hubUrl: 'https://hubapi.ergoprop.com/ergos/',
    socketsHubUrl: 'ws://hubapi.ergoprop.com/ergows/',
    apptoken: '1ae40f50e5b9f38d9bd000390fa161034c725b73ed83e96e7250074e6748b675',
    Session: 'user/session',
    publiImageKey: '?api_key=d4845b6dddaabb5e58a08311b5900998e474d2a8260a5a3cd69941eb5c731b49',
    ergoUser: 'ergoprop/_table/Users',
};

const siteConfig = {
    siteName: 'ERGOPROP',
    siteIcon: 'ion-flash',
    footerText: 'Ergoprop ©2017 Created by ShadoWorks, Ltd',
};

const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault',
};
const language = 'english';
const AlgoliaSearchConfig = {
    appId: '',
    apiKey: '',
};
const Auth0Config = {
    domain: '',
    clientID: '',
    allowedConnections: ['Username-Password-Authentication'],
    rememberLastLogin: true,
    language: 'en',
    closable: true,
    options: {
        auth: {
            autoParseHash: true,
            redirect: true,
            redirectUrl: 'http://localhost:3000/auth0loginCallback',
        },
        languageDictionary: {
            title: 'Ergoprop',
            emailInputPlaceholder: 'demo@gmail.com',
            passwordInputPlaceholder: 'demodemo',
        },
        theme: {
            labeledSubmitButton: true,
            logo: '',
            primaryColor: '#E14615',
            authButtons: {
                connectionName: {
                    displayName: 'Log In',
                    primaryColor: '#b7b7b7',
                    foregroundColor: '#000000',
                },
            },
        },
    },
};
const firebaseConfig = {
    apiKey: 'AIzaSyDhFSc55yzm-u19XpUQQOyCx0MAvGKvfTE',
    authDomain: 'ergoprop-1530901478143.firebaseapp.com',
    databaseURL: 'https://ergoprop-1530901478143.firebaseio.com',
    projectId: 'ergoprop-1530901478143',
    storageBucket: 'ergoprop-1530901478143.appspot.com',
    messagingSenderId: '485594242200',
    appId: '1:485594242200:web:ff7a77d8c3cf064a9184ed',
};
const googleConfig = {
    apiKey: 'AIzaSyAF3VL75j1_8VCBtC-dxZFYN07cjcjG5_w', //
};

const mapboxConfig = {
    tileLayer: '',
    maxZoom: '',
    defaultZoom: '',
    center: [],
};
const youtubeSearchApi = '';
export {
    siteConfig,
    ergoBe,
    themeConfig,
    language,
    AlgoliaSearchConfig,
    Auth0Config,
    firebaseConfig,
    googleConfig,
    mapboxConfig,
    youtubeSearchApi,
};
