import React from 'react';
import PropTypes from 'prop-types';

import {
    Collapse, Typography, Button, Tooltip, Spin
} from 'antd';

import Box from '../../components/utility/box';

const { Paragraph } = Typography;


class ToolBox extends React.Component {
    render() {
        const { AddModule,isLoadingTool } = this.props;
        return (
            <Box>
                <Paragraph>ToolBox</Paragraph>
                {isLoadingTool && <Spin />}
                <Collapse style={{ maxWidth: 220 }}>
                    <Collapse.Panel
                        collapsed={false}
                        header="Guiding Modules"
                    >
                        <Tooltip placement="topLeft" title="ShowImage " arrowPointAtCenter>
                            <Button
                                icon="picture"
                                style={{ margin: 5 }}
                                onClick={() => AddModule('GuideImage')}
                                shape="circle" />
                        </Tooltip>


                        <Tooltip placement="topLeft" title="Info Text " arrowPointAtCenter>
                            <Button
                                icon="file-text"
                                style={{ margin: 5 }}
                                onClick={() => AddModule('GuideText')}
                                shape="circle" />
                        </Tooltip>

                        <Tooltip placement="topLeft" title="Address" arrowPointAtCenter>
                            <Button
                                icon="global"
                                style={{ margin: 5 }}
                                onClick={() => AddModule('GuideAddress')}
                                shape="circle" />
                        </Tooltip>
                        <Tooltip placement="topLeft" title="GuideMap" arrowPointAtCenter>
                            <Button
                                icon="compass"
                                style={{ margin: 5 }}
                                onClick={() => AddModule('GuideMap')}
                                shape="circle" />
                        </Tooltip>


                    </Collapse.Panel>
                    <Collapse.Panel
                        header="Requests Modules"
                    >
                        <Tooltip placement="topLeft" title="Ask with Yes or No " arrowPointAtCenter>
                            <Button
                                style={{ margin: 5 }}
                                icon="question"
                                type="primary"
                                onClick={() => AddModule('Yes/No')}
                                shape="circle" />
                        </Tooltip>
                        <Tooltip placement="topLeft" title="Ask for a Photo " arrowPointAtCenter>
                            <Button
                                style={{ margin: 5 }}
                                icon="camera"
                                type="primary"
                                onClick={() => AddModule('Photo')}
                                shape="circle" />
                        </Tooltip>

                        <Tooltip placement="topLeft" title="Ask Long description" arrowPointAtCenter>
                            <Button
                                icon="form"
                                style={{ margin: 5 }}
                                type="primary"
                                onClick={() => AddModule('LongText')}
                                shape="circle" />
                        </Tooltip>

                        <Tooltip placement="topLeft" title="GPS Location Point" arrowPointAtCenter>
                            <Button
                                icon="global"
                                type="primary"
                                style={{ margin: 5 }}
                                onClick={() => AddModule('GPS')}
                                shape="circle" />
                        </Tooltip>

                        <Tooltip placement="topLeft" title="Ask Signature" arrowPointAtCenter>
                            <Button
                                icon="safety-certificate"
                                type="primary"
                                style={{ margin: 5 }}
                                onClick={() => AddModule('Signature')}
                                shape="circle" />
                        </Tooltip>


                    </Collapse.Panel>

                    <Collapse.Panel
                        header="Data Modules"
                    >
                        <Tooltip placement="topLeft" title="Ask for single Number" arrowPointAtCenter>
                            <Button
                                icon="question-circle"
                                style={{ margin: 5 }}
                                type="danger"
                                onClick={() => AddModule('Number')}
                                shape="circle" />
                        </Tooltip>
                        <Tooltip placement="topLeft" title="Measure" arrowPointAtCenter>
                            <Button
                                icon="pie-chart"
                                style={{ margin: 5 }}
                                type="danger"
                                onClick={() => AddModule('Measure')}
                                shape="circle" />
                        </Tooltip>
                    </Collapse.Panel>
                </Collapse>
            </Box>
        );
    }
}

ToolBox.propTypes = {
    AddModule: PropTypes.func.isRequired,
};

export default(ToolBox);
