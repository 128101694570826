/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input,
} from 'antd';
// import IntlMessages from '../../../components/utility/intlMessages';
import ButtonsComponent from '../ButtonsComponent';
import { ergoBe } from '../../../../../settings/index';
import { imgAuth } from '../../../../../network/request';


// SINGLE QUESTION OR ASSIGMENT COMPONENTS
class GuideImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl: '',
            title: props.element.element_title,
            textNoFilled: true,
            fireSave: false,
        };
    }

    componentDidMount() {
        const config1 = this.props.element.element_config;
        const config = JSON.parse(config1);

        if (config !== null) {
            this.setState({
                imagePreviewUrl: config.imagePreviewUrl,
            });
            this.setState({ textNoFilled: false });
        }
    }

    validate = () => {
        const {title, mandatory, imagePreviewUrl } = this.state;
        const tit = title && title.length > 3 && true;
        const image = imagePreviewUrl && imagePreviewUrl.length > 10 && true;
        if (tit && image) {
            this.setState({ textNoFilled: false });
            this.props.handleSaveElement(this.props.element, this.state);
            return;
        }
        this.setState({ textNoFilled: true });
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        setTimeout(() => { this.validate(); }, 2000);
    };


    // eslint-disable-next-line no-underscore-dangle
    _handleImageChange(e) {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            const headersPOST = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-DreamFactory-API-Key': ergoBe.apptoken,
                    'X-DreamFactory-Session-Token': this.props.Auth.dfUser.session_token,
                },
                method: 'POST',
                body: file,
            };
            // TODO: do something with -> this.state.file
            console.log('handle uploading-', this.state.file);
            fetch(`${ergoBe.beUrl}/Images/${this.props.Auth.ergoUser.user_id}/${file.name}`, headersPOST, {
            })
                .then(res => res.json())
                .then((data) => {
                    console.log(data);
                    this.setState({ imagePreviewUrl: `${ergoBe.beUrl}Images/${this.props.Auth.ergoUser.user_id}/${file.name}` });
                    this.validate();
                });
        };
        reader.readAsDataURL(file);
    }

    render() {
        const {
            element, activity,
        } = this.props;

        const { textNoFilled } = this.state;
        const { imagePreviewUrl } = this.state;
        let $imagePreview = null;

        if (imagePreviewUrl) {
            $imagePreview = (<img alt="mak" style={{ maxWidth: 300, maxHeight: 300 }} src={imagePreviewUrl + imgAuth()} />);
        } else {
            $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
        }
        const empty = !textNoFilled && this.state.title.length > 2 ? 'white' : '#f7a6b5';

        return (
            <Collapse style={{ backgroundColor: empty }}>
                <Collapse.Panel
                    key={element.element_id}
                    extra={
                        (
                            <ButtonsComponent
                                stopSave={() => this.setState({ fireSave: false })}
                                handleSave={this.state.fireSave}
                                color="default"
                                iconame="picture"
                                titlemod="IMAGE COMPONENT"
                                activity={activity}
                                enableSave={textNoFilled}
                                elementData={element}
                                elementState={this.state} />
                        )
                    }
                    header={this.state.title ? this.state.title : 'Show Guidance image'}
                >
                    <h4>Set image title:</h4>
                    <Input maxLength={50} placeholder={this.state.title} value={this.state.title} onChange={this.handleText} />
                    <div>
                        <div>
                            {$imagePreview}
                        </div>
                        <form onSubmit={e => this._handleSubmit(e)}>
                            <input
                                className="fileInput"
                                type="file"
                                onChange={e => this._handleImageChange(e)} />
                        </form>
                    </div>
                </Collapse.Panel>
            </Collapse>
        );
    }
}

GuideImage.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(GuideImage);
