import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Avatar,
} from 'antd';

import { Link } from 'react-router-dom';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { imgAuth } from '../../network/request';

const defprofile = 'https://cdn3.vectorstock.com/i/1000x1000/30/97/flat-business-man-user-profile-avatar-icon-vector-4333097.jpg';
const { logout } = authAction;

class TopbarUser extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false,
        };
    }

    hide() {
        this.setState({ visible: false });
    }

    handleVisibleChange() {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const { user, logout } = this.props;

        const content = user && user.firstname && user.lastname && (
            <TopbarDropdownWrapper className="isoUserDropdown">
                <h4 className="isoDropdownLink">
                    {user.name}
-
                 {user.lastname}
                </h4>
                <a className="isoDropdownLink">
                    <Link to="/dashboard/userProfile">User profile </Link>
                </a>

                <a className="isoDropdownLink" onClick={logout} href="# ">
                    <IntlMessages id="topbar.logout" />
                </a>
            </TopbarDropdownWrapper>
        );

        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    <div>
                        {!user && <Avatar size={40} icon="user" />}
                        {user && user.userPic ? <Avatar src={user.userPic + imgAuth()} size={40} /> : <Avatar src={defprofile} size={40} /> }
                    </div>
                    <span className="userActivity online" />
                </div>
            </Popover>
        );
    }
}
export default connect(
    null,
    { logout },
)(TopbarUser);
