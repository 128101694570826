import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input,
} from 'antd';
import ButtonsComponent from '../ButtonsComponent';

const { TextArea } = Input;

// SINGLE QUESTION OR ASSIGMENT COMPONENTS
class GuideText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatory: props.element.mandatory,
            title: props.element.element_title,
            textNoFilled: true,
            longText: '',
        };
    }

    componentDidMount() {
        const config1 = this.props.element.element_config;
        const config = JSON.parse(config1);

        if (config !== null) {
            this.setState({
                longText: config.longText,
                textNoFilled: false,
            });
        }
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        setTimeout(() => { this.validate(); }, 1000);
    };

    handleMandatory = (checkedValues) => {
        this.setState({ mandatory: checkedValues.target.checked });

    };

    validate = () => {
        const { mandatory, longText, title } = this.state;
        const tit = title && title.length > 3 && true;
        const long = longText && longText.length > 10 && true;


        if (long && tit) {
            this.setState({ textNoFilled: false });
            this.props.handleSaveElement(this.props.element, this.state);
            return;
        }
        this.setState({ textNoFilled: true });
    }


    render() {
        const {
            element, activity,
        } = this.props;
        const { textNoFilled } = this.state;


        const empty = !textNoFilled && this.state.title ? 'white' : '#f7a6b5';
        return (
            <Collapse style={{ backgroundColor: empty }}>
                <Collapse.Panel
                    header={this.state.title ? this.state.title : 'Set a Guidance Text'}
                    key={element.element_id}
                    extra={
                        (
                            <ButtonsComponent
                                color="default"
                                iconame="file-text"
                                titlemod="Guide Text"
                                activity={activity}
                                enableSave={textNoFilled}
                                elementData={element}
                                elementState={this.state} />
                        )
                    }
                >
                    <h4>Set Guide Text Title :</h4>
                    <Input
                        maxLength={50}
                        value={this.state.title}
                        onChange={this.handleText}
                        placeholder={this.state.title} />
                    <h4>Set Description:</h4>
                    <TextArea
                        value={this.state.longText}
                        onChange={e => this.setState({ longText: e.target.value })}
                        placeholder={this.state.longText}
                        autoSize={{ minRows: 3, maxRows: 5 }} />
                </Collapse.Panel>
            </Collapse>
        );
    }
}

GuideText.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(GuideText);
