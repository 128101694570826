import { Activity } from './reducer';



const ActivityActions = {
    LOAD_Activity: 'LOAD_Activity',
    Activity_LOADED: 'Activity_LOADED',
    ADD_Activity: 'ADD_Activity',
    EDIT_Activity: 'EDIT_Activity',
    DELETE__Activity: 'DELETE__Activity',
    CHANGE_Activity: 'CHANGE_Activity',
    EDIT_VIEW: 'EDIT_VIEW',

    changeActivity: id => ({
        type: ActivityActions.CHANGE_Activity,
        id,
    }),

    addActivity: () => {
        const newActivity = {
            id: new Date(),
            firstName: '',
            avatar: Activity[new Date() % 10].avatar,
            LastName: '',
            mobile: '',
            home: '',
            name: '',
            company: '',
            work: '',
            note: '',

        };
        return (dispatch, getState) => {
            dispatch({
                type: ActivityActions.ADD_Activity,
                Activity: [...getState().Activity.Activity, newActivity],
                selectedId: newActivity.id,
            });
        };
    },

    deleteActivity: id => (dispatch, getState) => {
        const Activity = getState().Activity.Activity;
        const seectedId = getState().Activity.seectedId;
        const newActivity = [];
        Activity.forEach((Activity) => {
            if (Activity.id === id) {
            } else {
                newActivity.push(Activity);
            }
        });
        dispatch({
            type: ActivityActions.DELETE__Activity,
            Activity: newActivity,
            seectedId: id === seectedId ? undefined : seectedId,
        });
    },
    viewChange: view => ({
        type: ActivityActions.EDIT_VIEW,
        view,
    }),

    LoadActivity: (id, userid, status) => (dispatch) => {
        dispatch({
            type: ActivityActions.LOAD_Activity,
            Activityid: id,
            status,
            userid,
            Activity,
        });
    },

    ActivityLoaded: () => ({
        type: ActivityActions.Activity_LOADED,
        Activity,

    }),
};
export default ActivityActions;
