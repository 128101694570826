import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';


import './mobileCont.css';
import { Tabs, Col, Icon } from 'antd';
import SingleTemplateAction from '../../../redux/SingleTemplate/actions';
import PageHeader from '../../../components/utility/pageHeader';
import Box from '../../../components/utility/box';
import { ergoBe } from '../../../settings/index';
import { makeHeadersPost } from '../../../network/request';
import Notification from '../../../components/notification';
import IntlMessages from '../../../components/utility/intlMessages';
import PrivateForm from './PrivateForm';
import PublicForm from './PublicForm';
import privateNetwork from '../../../image/privateNetwork.jpg';


const { TabPane } = Tabs;

const LayoutContentWrapper = styled.div`
  display: block;
`;

const ImageCol = styled(Col)`
    display: flex;
    align-content: flex-end;
    justify-content: center;
`;

const {
    LoadSingleTemplate,
} = SingleTemplateAction;


class WorkPublisher extends React.Component {
    constructor(props) {
        super(props);
        // const sorted = props.activity.sort((a, b) => a.order > b.order);
        this.state = {
            userSelected: '',
            taskType: '',
            StartDate: '',
            StartTime: '',
            ExpectedDays: '',
            ExpectedHours: '',
            ExpectedMins: '',
            disabled: true,
            activity: this.props.history.location.state.activity,
        };
        this.goBack = this.goBack.bind(this);
    }


    PublishTemplate = () => {
        const { activity } = this.props.history.location.state;

        const activityPublish = {
            resource: [
                {
                    name: activity.name,
                    execType: this.state.taskType,
                    // startDate: fv.startdate,
                    // startTime: fv.starttime,
                    // ExpDurMin: this.state.ExpectedMins,
                    // ExpDurHours: this.state.ExpectedHours,
                    // ExpDurDays: this.state.ExpectedDays,
                    // ExpStartTime: this.state.StartTime,
                    // ExpStartDate: this.state.StartDate,
                    weight: 251,
                    wkchildid: 251,
                    origin: 'FROM-NETWORK',
                    project_id: 50,
                    owner_userid: this.props.Auth.ergoUser.user_id,
                    ownerData: JSON.stringify(this.props.Auth.ergoUser),
                    status: 'PUBLISHED',
                    description: activity.description,
                    network: 'PRIVATE',
                    assigned_user_id: this.state.userSelected,
                    activityTid: activity.activityTid,
                    imageUrl: activity.image,
                    stateTemplate: JSON.stringify(activity),
             
                },

            ],
        };

        const activitiesUrl = `${ergoBe.beUrl}ergoprop/_table/activitiesforms`;
        fetch(activitiesUrl, makeHeadersPost(activityPublish))
            .then(response => response.json())
            .then((myJson) => {
                Notification(
                    'success',
                    'PRIVATE EXECUTOR NETWORK',
                    'The activity has been published ',
                );
                // CREATE FOLDER
                console.log(this.props);
                const headersPOSTFolder = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'X-DreamFactory-API-Key': ergoBe.apptoken,
                        'Content-Type': 'application/json',
                        'X-DreamFactory-Session-Token': this.props.Auth.dfUser.session_token,
                        'X-Folder-Name': myJson.resource[0].activity_id,
                    },

                };
                const regUrl4 = `${ergoBe.beUrl}Images/registrations/`;

                fetch(regUrl4, headersPOSTFolder)
                    .then((response) => {
                        if (!response.ok) {
                            Notification(
                                'error',
                                JSON.stringify(response),
                            );
                        }
                        return response;
                    }).then(response => response.json())
                    .then((folderRes) => {
                        if (folderRes.resource) {

                        }
                    })
                    .catch((error) => {
                        Notification(
                            'Failed',
                            'Something really went wrong',
                            JSON.stringify(error),

                        );
                    });


                this.props.history.push('/dashboard/Templates_list');
            })
            .catch(error => console.error('Error:', error));
    };

    PublishToHub = (formData) => {
        // console.log(activity);
        console.log(formData);
        const activity = formData.ActivityForm;
        const activityPublish = {
            resource: [
                {
                    // startTime: fv.starttime,
                    // ExpDurMin: this.state.ExpectedMins,
                    // ExpDurHours: formData.expDuration,
                    // ExpDurDays: this.state.ExpectedDays,
                    // ExpStartTime: this.state.StartTime,
                    // ExpStartDate: this.state.StartDate,
                    location: this.state.location,
                    name: activity.name,
                    anonymization: formData.anonymization,
                    category: formData.category,
                    comments: formData.comments,
                    productsOffers: formData.productsOffers,
                    startdatedue: formData.startdatedue,
                    startdatefrom: formData.startdatefrom,
                    visErgolabos: formData.visErgolabos,
                    visSuppliers: formData.visSuppliers,
                    visabilityErgolabous: formData.visabilityErgolabous,
                    loclat: formData.location.lat,
                    loclng: formData.location.lng,
                    extracomments: formData.comments,
                    network: 'PUBLIC',
                    owner_userid: this.props.Auth.ergoUser.user_id,
                    ownerData: JSON.stringify(this.props.Auth.ergoUser),
                    status: 'PUBLIC',
                    description: activity.description,
                    weight: 251,
                    wkchildid: 251, // / HARDCODED FOR OLD ERGOPROP
                    project_id: 50,
                    activityTid: activity.activityTid,
                    origin: 'TEMPLATE_HUB',
                    imageUrl: activity.image,
                    stateTemplate: JSON.stringify(activity),
                },

            ],
        };


        const dats = {
            region: 'NED',
            category_id: 1,
            country_id: 1,
            data: activityPublish.resource[0],
        };


        // PUBLISHING TO HUB
        fetch(ergoBe.hubUrl, {
            body: JSON.stringify(dats),
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/Json',
            },
            method: 'POST',
        });
        this.goToTemplates();
        Notification(
            'success',
            'Activity has been published to public users network you should wait for offer',
        );
    };


    onDaysChange = (value) => {
        this.setState({ ExpectedDays: value });
    };

    onHoursChange = (value) => {
        this.setState({ ExpectedHours: value });
    };

    setLocation = (value) => {
        console.log(value);
    };

    onMinutesChange = (value) => {
        this.setState({ ExpectedMins: value });
    };

    onChange = (value) => {
        this.setState({ userSelected: value, disabled: false });
        if (!value) {
            this.setState({ disabled: true });
        }
    };

    onChangeType = (value) => {
        this.setState({ taskType: value, disabled: false });
        console.log(value);
        if (!value) {
            this.setState({ disabled: true });
        }
    };

    onDateChange = (value, dateString) => {
        this.setState({ StartDate: dateString });
    };

    onTimeChange = (value, dateString) => {
        this.setState({ StartTime: dateString });
    };

    goBack = () => {
        console.log(this.props.history);
        this.props.history.goBack();
    };

    goToTemplates = () => {
        console.log(this.props.history);
        this.props.history.push('/dashboard/Templates_list');
    };

    render() {
        const { activity } = this.state;
        return (
            <LayoutContentWrapper>
                <Box>
                    <Tabs defaultActiveKey="1">
                        <TabPane
                            tab={(
                                <span>
                                    <Icon type="user" />
                                    <IntlMessages id="WorkViewr.index.FirstTab" />
                                </span>
                            )
                            }
                            key="1"
                        >
                            <PageHeader>
                                {activity.name}
                                 To my users network
                            </PageHeader>

                            <Col span={12}>
                                <div style={{ paddingLeft: '5%' }}>
                                    <PrivateForm
                                        disabled={this.state.disabled}
                                        publishTemplate={this.PublishTemplate}
                                        change={this.onChange}
                                        focus={this.onFocus}
                                        blur={this.onBlur}
                                        search={this.onSearch}
                                        dateChange={this.onDateChange}
                                        timeChange={this.onTimeChange}
                                        daysChange={this.onDaysChange}
                                        hoursChange={this.onHoursChange}
                                        onCanchel={this.goToTemplates}
                                        onBack={this.goBack}
                                        taskType={this.onChangeType}
                                        minutesChange={this.onMinutesChange} />
                                </div>
                            </Col>
                            <ImageCol span={12}>
                                <img src={privateNetwork} width="50%" alt="Private Network" />
                            </ImageCol>
                        </TabPane>
                        <TabPane
                            tab={(
                                <span>
                                    <Icon type="global" />
                                    <IntlMessages id="WorkViewr.index.SecondTab" />
                                </span>
                            )
                            }
                            key="2"
                        >
                            <PageHeader>
                                {activity.name}
                                -> To Pulic Hub
                            </PageHeader>
                            <Col>
                                <div style={{ paddingLeft: '1%' }}>
                                    <PublicForm
                                        disabled={false}
                                        activity={activity}
                                        selectedLocation={this.setLocation}
                                        publishHub={this.PublishToHub}
                                        dateChange={this.onDateChange}
                                        timeChange={this.onTimeChange}
                                        daysChange={this.onDaysChange}
                                        hoursChange={this.onHoursChange}
                                        onCanchel={this.goToTemplates}
                                        onBack={this.goBack}
                                        minutesChange={this.onMinutesChange} />
                                </div>
                            </Col>
                        </TabPane>
                    </Tabs>
                </Box>
            </LayoutContentWrapper>
        );
    }
}

WorkPublisher.propTypes = {
    history: object.isRequired,
};
function mapStateToProps(state) {
    return {
        ...state,
        isLoading: state.SingleTemplate.TempisLoading,
    };
}
export default connect(mapStateToProps, {
    LoadSingleTemplate,
})(WorkPublisher);
