import React, { Component } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import './mobileCont.css';
import { Button, Icon, Select } from 'antd';
import SingleTemplateAction from '../../../redux/SingleTemplate/actions';
import IntlMessages from '../../../components/utility/intlMessages';
import SelectUser from './modules/SelectUser';
import SelectDataAndTime from './modules/SelectDataAndTime';
import SelectDuration from './modules/SelectDuration';
import Autocomplete from '../../components/AutoAddress';

const {
    LoadSingleTemplate,
} = SingleTemplateAction;
const { Option } = Select;

class PrivateForm extends Component {
    render() {
        const {
            dateChange, timeChange, onBack, onCanchel, taskType, daysChange, hoursChange, minutesChange, disabled, publishTemplate, change, focus, blur, search,
        } = this.props;

        return (
            <div>
                <SelectUser
                    onChange={change}
                    onFocus={focus}
                    nBlur={blur}
                    onSearch={search} />
                <div className="isoHelperText">
                 Execution Mode
                </div>
                <Select defaultValue style={{ width: 120 }} onChange={taskType}>
                    <Option value="Once">Once</Option>
                    <Option value="Recursive"> Recursive</Option>
                </Select>
                <SelectDataAndTime
                    onDateChange={dateChange}
                    onTimeChange={timeChange} />
                <SelectDuration
                    onDaysChange={daysChange}
                    onHoursChange={hoursChange}
                    onMinutesChange={minutesChange} />
                <div>
                    <div>
                    Address:
                        <Autocomplete />
                    </div>
                    <span className="button">
                        <Button
                            disabled={disabled}
                            onClick={publishTemplate}
                            type="success"
                        >
                            <IntlMessages id="WorkViewr.index.assignWork" />
                        </Button>
                    </span>
                    <span className="button">
                        <Button
                            type="button"
                            onClick={onCanchel}
                        >
                            <IntlMessages id="WorkViewr.index.cancel" />
                        </Button>
                    </span>
                    <span className="button">
                        <Button
                            onClick={onBack}
                            type="primary"
                        >
                            <Icon type="left" />
                        Backward
                        </Button>
                    </span>
                </div>
            </div>
        );
    }
}

PrivateForm.propTypes = {
    disabled: object.isRequired,
    publishTemplate: object.isRequired,
    change: object.isRequired,
    focus: object.isRequired,
    blur: object.isRequired,
    search: object.isRequired,
    dateChange: object.isRequired,
    timeChange: object.isRequired,
    daysChange: object.isRequired,
    hoursChange: object.isRequired,
    minutesChange: object.isRequired,
};

function mapStateToProps(state) {
    return {
        ...state,
        isLoading: state.SingleTemplate.TempisLoading,
    };
}

export default connect(mapStateToProps, {
    LoadSingleTemplate,
})(PrivateForm);
