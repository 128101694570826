import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import GoogleMapReact from 'google-map-react';
import NoAPIKey from '../../../../../components/utility/noApiKey';
import { googleConfig } from '../../../../../settings/index';

const AnyReactComponent = ({ text }) => <div style={{ backgroundColor: 'red', height: 20, width: 20 }}>{text}</div>;
// SINGLE QUESTION OR ASSIGMENT COMPONENTS
class MapModal extends Component {
    static defaultProps = {
        center: {
            lat: 35.95,
            lng: 15.33,
        },
        zoom: 4,
    };

      onMapClick =(value) => {
          this.props.selectedLocations(value);
      }

      render() {
          const {
              visible, onOk, onCancel, lat, lng,
          } = this.props;

          const latg = lat || 35.95;
          const lngg = lng || 15.33;

          return (
              <Modal
                  title="Select location "
                  visible={visible}
                  onOk={onOk}
                  onCancel={onCancel}
              >
                  {googleConfig.apiKey ? (
                      <div>
                          <div style={{ height: 400, width: 400 }}>
                              <GoogleMapReact
                                  bootstrapURLKeys={{ key: googleConfig.apiKey }}
                                  //   onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                                  defaultCenter={this.props.center}
                                  defaultZoom={this.props.zoom}
                                  onClick={this.onMapClick}
                              >
                                  <AnyReactComponent
                                      lat={latg}
                                      lng={lngg}
                                      text="ErgoPoint" />
                              </GoogleMapReact>
                          </div>
                      </div>

                  ) : (
                      <NoAPIKey />
                  )}
              </Modal>
          );
      }
}

MapModal.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(MapModal);
