import ElementsActions from './actions';

const Elements = [];
const initState = {
    Elements,
    ElemisLoading: false,
};

export default function ElementsReducer(state = initState, action) {
    switch (action.type) {
    case ElementsActions.Elements_UPDATED:
        return {
            ...state,
            Elements: action.Elements,
            ElemisLoading: false,
        };
    case ElementsActions.UPDATE_Elements:
        return {
            ...state,
            ElemisLoading: true,
        };
    case ElementsActions.DElETE_Elements:
        return {
            ...state,
            element: action.element,
            ElemisLoading: true,
        };
    case ElementsActions.Elements_DELETED:
        return {
            ElemisLoading: false,
        };
    default:
        return state;
    }
}
export { Elements };
