import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input, InputNumber, Button, Icon, Row,
} from 'antd';
import { Bar } from 'react-chartjs-2';
import { data, barSettings } from '../../../../containers/Charts/reactChart2/components/bar/barConfig';
import NoAPIKey from '../../../../components/utility/noApiKey';
import { googleConfig, ergoBe } from '../../../../settings/index';
import PreviewMap from '../../ErgoHub/previewmap';
import Box from '../../../../components/utility/box';
import IntlMessages from '../../../../components/utility/intlMessages';
import ReadOnlyMap from '../../../components/readOnlyMap';
// import IntlMessages from '../../../components/utility/intlMessages';

// SINGLE QUESTION OR ASSIGMENT COMPONENTS
class SinglePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mandatory: props.element.mandatory,
            element: props.element,
        };
    }

    componentWillReceiveProps(next) {
        this.setState({ element: next.element });
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        if (event.target.value.length > 5) {
            this.setState({ textNoFilled: false });
        }
        if (event.target.value.length < 5) {
            this.setState({ textNoFilled: true });
        }
    };

    handleMandatory = (checkedValues) => {
        this.setState({ mandatory: checkedValues.target.checked });
    };

     photo = element => (
         <Collapse.Panel header={element.element_title} key={element.element_id}>

         </Collapse.Panel>
     );

     text = element => (
         <Collapse.Panel header={element.element_title} key={element.element_id}>
             <Input />
         </Collapse.Panel>
     );

     longtext = element => (
         <Collapse.Panel header={element.element_title} key={element.element_id}>
             <Input />
         </Collapse.Panel>
     );

     yesno= element => (
         <Collapse.Panel header={element.element_title} key={element.element_id}>
         </Collapse.Panel>
     );

    signature = element => (
        <Collapse.Panel header={element.element_title} key={element.element_id}>
            <Input placeholder="Put your signature" />

        </Collapse.Panel>
    );

    metrix = element => (
        <Collapse.Panel header={element.element_title} key={element.element_id}>
            <div>
                <Bar
                    data={data}
                    width={barSettings.width}
                    height={barSettings.height}
                    options={{ maintainAspectRatio: false }} />
            </div>
        </Collapse.Panel>

    );

    number = element => (
        <Collapse.Panel header={element.element_title} key={element.element_id}>
            <div>
                <InputNumber min={1} max={10} defaultValue={3} />
            </div>

        </Collapse.Panel>

    );

    file = element => (
        <Collapse.Panel header={element.element_title} key={element.element_id}>
            <div>
                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                    <Icon type="plus" />
                    {' '}
                    Add field
                </Button>
            </div>

        </Collapse.Panel>

    );

    gps = element => (
        <Collapse.Panel header={element.element_title} key={element.element_id}>

            <h4> System will track user location</h4>
        </Collapse.Panel>

    );

    measure = (element) => {
        const config1 = element.element_config;
        const config = JSON.parse(config1);

        return (
            <Collapse.Panel header={element.element_title} key={element.element_id}>
                <h4>
                    {config && config.measureType ? config.measureType : "Not Selected"}
                  
                </h4>
            </Collapse.Panel>
        );
    }

    guideMap = (element) => {
        const config1 = element.element_config;
        const config = JSON.parse(config1);
        const lats = config && config.lat ? config.lat : null;
        const lngs = config && config.lng ? config.lng : null;

        return (
            <Collapse.Panel header={element.element_title} key={element.element_id}>
                {googleConfig.apiKey ? (
                    <div>
                        {googleConfig.apiKey ? (
                            <ReadOnlyMap
                                width="100%"
                                height={150}
                                zoom={8}
                                googleConfig={googleConfig}
                                lat={lats}
                                lng={lngs}
                                mapHeight={50} />
                        ) : null
                        }
                    </div>
                ) : (
                    <NoAPIKey />
                )}
            </Collapse.Panel>
        );
    }


    guideAddress = (element) => {
        const config = JSON.parse(element.element_config);
        return (
            <Collapse.Panel header={element.element_title} key={element.element_id}>
                <h4>
                    {config && config.address ? config.address : null}
                    {}
                </h4>
                <h5>
                    {config && config.houseNumber ? config.houseNumber : null }
                    {config && config.postCode ? config.postCode : null }
                    {}
                </h5>
            </Collapse.Panel>
        );
    }

    guideText= (element) => {
        const config = JSON.parse(element.element_config);
        return (
            <Collapse.Panel header={element.element_title} key={element.element_id}>
                <h5>
                    {config && config.longText ? config.longText : null }
                    {}
                </h5>

            </Collapse.Panel>
        );
    }

    guideImage= (element) => {
        const imgAuth = `?session_token=${this.props.Auth.dfUser.session_token}&api_key=${ergoBe.apptoken}`;
        const config = JSON.parse(element.element_config);


        return (
            <Collapse.Panel header={element.element_title} key={element.element_id}>
                <img alt="maks" style={{ maxWidth: 100, maxHeight: 100 }} src={config && config.imagePreviewUrl ? config.imagePreviewUrl + imgAuth : null} />
)
            </Collapse.Panel>
        );
    }


    render() {
        const {
            element,
        } = this.state;


        return (
            <Collapse>
                {element.element_type === 'Photo' && this.photo(element)}
                {element.element_type === 'Text' && this.text(element)}
                {element.element_type === 'Yes/No' && this.yesno(element)}
                {element.element_type === 'Signature' && this.signature(element)}
                {element.element_type === 'Metrix' && this.metrix(element)}
                {element.element_type === 'Number' && this.number(element)}
                {element.element_type === 'Measure' && this.measure(element)}
                {element.element_type === 'File' && this.file(element)}
                {element.element_type === 'GPS' && this.gps(element)}
                {element.element_type === 'LongText' && this.longtext(element)}
                {element.element_type === 'GuideMap' && this.guideMap(element)}
                {element.element_type === 'GuideAddress' && this.guideAddress(element)}
                {element.element_type === 'GuideText' && this.guideText(element)}
                {element.element_type === 'GuideImage' && this.guideImage(element)}
            </Collapse>
        );
    }
}

SinglePreview.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(SinglePreview);
