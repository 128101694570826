import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input,
} from 'antd';
import ButtonsComponent from '../ButtonsComponent';

const { TextArea } = Input;

// SINGLE QUESTION OR ASSIGMENT COMPONENTS
class GuideAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatory: props.element.mandatory,
            title: props.element.element_title,
            address: '',
            houseNumber: '',
            postCode: '',
            floorNumber: '',
            textNoFilled: true,
        };
    }

    componentDidMount() {
        const config1 = this.props.element.element_config;
        const config = JSON.parse(config1);

        if (config !== null) {

            this.setState({
                address: config.address,
                houseNumber: config.houseNumber,
                postCode: config.postCode,
                floorNumber: config.floorNumber,
                textNoFilled: false,
            });
        }

    }


    validate = () => {
        const {
            title, mandatory, address, postCode, houseNumber, floorNumber,
        } = this.state;

        const tit = title && title.length > 3 && true;
        const addres = address && address.length > 3 && true;
        const postcod = postCode && postCode.length > 3 && true;
        const houseNum = houseNumber && houseNumber.length > 0 && true;
        const floorum = floorNumber && floorNumber.length > 0 && true;

        if (tit && addres && postcod && houseNum && floorum) {
            this.setState({ textNoFilled: false });
            this.props.handleSaveElement(this.props.element, this.state);
            return;
        }
        this.setState({ textNoFilled: true });
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        setTimeout(() => { this.validate(); }, 1000);
    };

    handleAddress = (type, event) => {
        this.setState({ [type]: event.target.value });
        setTimeout(() => { this.validate(); }, 1000);
    };

    handleMandatory = (checkedValues) => {
        this.setState({ mandatory: checkedValues.target.checked });
        setTimeout(() => { this.validate(); }, 1000);
    };


    render() {
        const {
            element, activity,
        } = this.props;


        const { textNoFilled } = this.state;
        const empty = !textNoFilled && this.state.title.length > 2 ? 'white' : '#f7a6b5';
     //   const updateSend = !textNoFilled && this.state.title.length > 2 && this.props.validMods(!this.state.textNoFilled);
        return (
            <Collapse style={{ backgroundColor: empty }}>
                <Collapse.Panel
                    header={this.state.title ? this.state.title : 'Set Guidance Address'}
                    key={element.element_id}
                    extra={
                        (
                            <ButtonsComponent
                                color="default"
                                iconame="global"
                                titlemod="ADDRESS-MODULE"
                                activity={activity}
                                enableSave={textNoFilled}
                                elementData={element}
                                elementState={this.state} />
                        )
                    }
                >
                    <h4>Set address Title :</h4>
                    <TextArea
                        value={this.state.title}
                        onChange={this.handleText}
                        placeholder={this.state.title}
                        autoSize={{ minRows: 1, maxRows: 1 }} />
                    <h4>Address:</h4>
                    <Input maxLength={50} placeholder={this.state.adddress} value={this.state.address} onChange={e => this.handleAddress('address', e)} />
                    <h4>House Number:</h4>
                    <Input placeholder={this.state.houseNumber} value={this.state.houseNumber} onChange={e => this.handleAddress('houseNumber', e)} />
                    <h4>PostCode:</h4>
                    <Input placeholder={this.state.postCode} value={this.state.postCode} onChange={e => this.handleAddress('postCode', e)} />
                    <h4>floorNumber:</h4>
                    <Input placeholder={this.state.floorNumber} value={this.state.floorNumber} onChange={e => this.handleAddress('floorNumber', e)} />

                </Collapse.Panel>
            </Collapse>
        );
    }
}

GuideAddress.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(GuideAddress);
