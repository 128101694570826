import 'whatwg-fetch';
import { ergoBe } from '../settings/index';
import { getToken } from '../helpers/utility';


function handleErrors(response) {
    if (!response.ok) {
        console.log(response.status);
    }
    return response;
}


export function request(url, options) {
    return fetch(url, options)
        .then(response => response.json())
        .then(data => data);
}

export function makeHeaders() {
    const token = getToken().get('idToken');
    const ergoHeaders = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'X-DreamFactory-API-Key': ergoBe.apptoken,
            'Content-Type': 'application/json',
            'X-DreamFactory-Session-Token': token,
        },

    };
    return ergoHeaders;
}

export function imgAuth() {
    const token = getToken().get('idToken');
    const img = `?session_token=${token}&api_key=${ergoBe.apptoken}`;
    return img;
}

export function makeHeadersPost(data) {
    const token = getToken().get('idToken');
    const ergoHeaders = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'X-DreamFactory-API-Key': ergoBe.apptoken,
            'Content-Type': 'application/json',
            'X-DreamFactory-Session-Token': token,
        },
        body: JSON.stringify(data),

    };
    return ergoHeaders;
}

export function makeHeadersPut(data) {
    const token = getToken().get('idToken');
    const ergoHeaders = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'X-DreamFactory-API-Key': ergoBe.apptoken,
            'Content-Type': 'application/json',
            'X-DreamFactory-Session-Token': token,
        },
        body: JSON.stringify(data),
    };
    return ergoHeaders;
}

export function makeHeadersPatch(data) {
    const token = getToken().get('idToken');
    const ergoHeaders = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'X-DreamFactory-API-Key': ergoBe.apptoken,
            'Content-Type': 'application/json',
            'X-DreamFactory-Session-Token': token,
        },
        body: JSON.stringify(data),
    };
    return ergoHeaders;
}


export function makeHeadersDelete() {
    const token = getToken().get('idToken');
    const ergoHeaders = {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'X-DreamFactory-API-Key': ergoBe.apptoken,
            'Content-Type': 'application/json',
            'X-DreamFactory-Session-Token': token,
        },
    };
    return ergoHeaders;
}

export function callDF(url) {
    return fetch(url, makeHeaders())
        .then(handleErrors)
        .then(response => response.json())
        .then(data => data);
}

export function putDF(url, datas) {
    return fetch(url, makeHeadersPut(datas))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => data);
}

export function deleteDF(url) {
    return fetch(url, makeHeadersDelete())
        .then(handleErrors)
        .then(response => response.json());
}
