import { addLocaleData } from 'react-intl';
import Enlang from './entries/en-US';
import Grlang from './entries/el_GR';

const AppLocale = {
    en: Enlang,
    gr: Grlang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.gr.data);

export default AppLocale;
