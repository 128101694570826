import { Offers } from './reducer';


const OffersActions = {
    LOAD_Offers: 'LOAD_Offers',
    Offers_LOADED: 'Offers_LOADED',
    ADD_Offers: 'ADD_Offers',
    EDIT_Offers: 'EDIT_Offers',
    DELETE__Offers: 'DELETE__Offers',
    CHANGE_Offers: 'CHANGE_Offers',
    EDIT_VIEW: 'EDIT_VIEW',

    changeOffers: id => ({
        type: OffersActions.CHANGE_Offers,
        id,
    }),

    addOffers: () => {
        const newOffers = {
            id: new Date(),
            firstName: '',
            avatar: Offers[new Date() % 10].avatar,
            LastName: '',
            mobile: '',
            home: '',
            name: '',
            company: '',
            work: '',
            note: '',

        };
        return (dispatch, getState) => {
            dispatch({
                type: OffersActions.ADD_Offers,
                Offers: [...getState().Offers.Offers, newOffers],
                selectedId: newOffers.id,
            });
        };
    },

    deleteOffers: id => (dispatch, getState) => {
        const Offers = getState().Offers.Offers;
        const seectedId = getState().Offers.seectedId;
        const newOffers = [];
        Offers.forEach((Offers) => {
            if (Offers.id === id) {
            } else {
                newOffers.push(Offers);
            }
        });
        dispatch({
            type: OffersActions.DELETE__Offers,
            Offers: newOffers,
            seectedId: id === seectedId ? undefined : seectedId,
        });
    },
    viewChange: view => ({
        type: OffersActions.EDIT_VIEW,
        view,
    }),

    LoadOffers: userid => (dispatch) => {
        dispatch({
            type: OffersActions.LOAD_Offers,
            userid,
        });
    },

    OffersLoaded: () => ({
        type: OffersActions.Offers_LOADED,
        Offers,

    }),
};
export default OffersActions;
