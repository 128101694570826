import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import OffersActions from './actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';

export function* addOffers() {
    yield takeEvery(OffersActions.ADD_Offers, function* () {});
}
export function* editOffers() {
    yield takeEvery(OffersActions.EDIT_Offers, function* () {});
}
export function* deleteOffers() {
    yield takeEvery(OffersActions.DELETE__Offers, function* () {});
}


export function* LoadOffers() {
    yield takeEvery(OffersActions.LOAD_Offers, function* (data) {
        const { userid } = data;
        const url = ergoBe.beUrl+ "ergoprop/_table/ActivityOffers?filter=(owneruser="+userid+")%20and%20(status=SENDTOOWNER)";
        const response = yield callDF(url);
        if (response) {
            yield put({
                type: OffersActions.Offers_LOADED,
                Offers: response.resource,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(LoadOffers)]);
}
