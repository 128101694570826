import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import actions from './actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';


export function* addContact() {
    yield takeEvery(actions.ADD_CONTACT, function* () {});
}
export function* editContact() {
    yield takeEvery(actions.EDIT_CONTACT, function* () {});
}
export function* deleteContact() {
    yield takeEvery(actions.DELETE__CONTACT, function* () {});
}

export function* LoadContact() {
    yield takeEvery(actions.LOAD_CONTACT, function* (data) {
        console.log(data.userid);
        const compUsers = `${ergoBe.beUrl}ergoprop/_table/UsersHub?filter=user_id=`+data.userid;
        const response = yield callDF(compUsers);
        if (response) {
            yield put({
                type: actions.CONTACT_LOADED,
                contacts: response.resource,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(addContact), fork(editContact), fork(deleteContact), fork(LoadContact)]);
}
