import OffersActions from './actions';

const Offers = [];
const initState = {
    Offers,
    seectedId: [],
    editView: false,
    OffersisLoading: false,
};

export default function OffersReducer(state = initState, action) {
    switch (action.type) {
    case OffersActions.CHANGE_Offers:
        return {
            ...state,
            seectedId: action.id,
            editView: false,
        };
    case OffersActions.ADD_Offers:
        return {
            ...state,
            Offers: action.Offers,
            seectedId: action.selectedId,
            editView: true,
        };
    case OffersActions.EDIT_Offers:
        return {
            ...state,
            Offers: action.Offers,
        };
    case OffersActions.DELETE__Offers:
        return {
            ...state,
            Offers: action.Offers,
            seectedId: action.seectedId,
        };
    case OffersActions.LOAD_Offers:
        return {
            ...state,
            OffersisLoading: true,
        };
    case OffersActions.Offers_LOADED:
        return {
            ...state,
            Offers: action.Offers,
            OffersisLoading: false,
        };
    case OffersActions.EDIT_VIEW:
        return {
            ...state,
            editView: action.view,
        };
    default:
        return state;
    }
}
export { Offers };
