import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input, Icon, Button,
} from 'antd';
// import IntlMessages from '../../../components/utility/intlMessages';
import ButtonsComponent from '../ButtonsComponent';
import { googleConfig } from '../../../../../settings/index';
import ReadOnlyMap from '../../../../components/readOnlyMap';
import MapModal from './MapModal';

// SINGLE QUESTION OR ASSIGMENT COMPONENTS
class GuideMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mandatory: props.element.mandatory,
            title: props.element.element_title,
            textNoFilled: true,
            lat: '',
            lng: '',
            x: '',
            y: '',
            visible: false,
        };
    }

    componentDidMount() {
        const config1 = this.props.element.element_config;
        const config = JSON.parse(config1);

        if (config !== null) {
            this.setState({
                lat: config.lat,
                lng: config.lng,
                x: config.x,
                y: config.y,
                textNoFilled: false,
            });
        }
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        setTimeout(() => { this.validate(); }, 1000);
    };

    handleMandatory = (checkedValues) => {
        this.setState({ mandatory: checkedValues.target.checked });
        setTimeout(() => { this.validate(); }, 1000);
    };


    HeaderText = () => (
        <div>
            <Icon type="global" style={{ fontSize: '16px', color: '#08c', marginRight: 5 }} />
            { this.state.title }
        </div>
    );

    openMapModal = (element) => {
        this.setState({
            visible: true,
        });
    }

    handleOk= () => {
        this.setState({ visible: false });
    }

     handleCancel= () => {
         this.setState({ visible: false });
     }

     setLocation = (value) => {
         this.setState({
             lat: value.lat,
             lng: value.lng,
             x: value.x,
             y: value.y,
         });
         setTimeout(() => { this.validate(); }, 200);
     };

     validate = () => {
         const {lat, mandatory, lng, title } = this.state;
         const tit = title && title.length > 3 && true;

         if (lat && lng && tit) {
             this.setState({ textNoFilled: false });
             this.props.handleSaveElement(this.props.element, this.state);
             return;
         }
         this.setState({ textNoFilled: true });
     }


     render() {
         const {
             element, activity,
         } = this.props;


         const { textNoFilled } = this.state;
         const empty = !textNoFilled && this.state.title ? 'white' : '#f7a6b5';
         return (
             <Collapse style={{ backgroundColor: empty }}>
                 <MapModal
                     visible={this.state.visible}
                     onOk={this.handleOk}
                     element={element}
                     onCancel={this.handleCancel}
                     lat={this.state.lat}
                     lng={this.state.lng}
                     selectedLocations={this.setLocation} />
                 <Collapse.Panel
                     header={this.state.title ? this.state.title : 'Set Guidance location point'}
                     key={element.element_id}
                     extra={
                         (
                             <ButtonsComponent
                                 color="default"
                                 iconame="compass"
                                 titlemod="MAP-POINT-MODULE"
                                 activity={activity}
                                 enableSave={textNoFilled}
                                 elementData={element}
                                 elementState={this.state} />
                         )
                     }
                 >
                     <h4>Set Location point Title :</h4>
                     <Input maxLength={50} placeholder={this.state.title} value={this.state.title} onChange={this.handleText} />
                     <Button onClick={this.openMapModal}>
                         {this.state.lat && this.state.lng ? 'SET NEW LOCATION' : 'SET LOCATION'}
                     </Button>

                     {this.state.lat && this.state.lng && googleConfig.apiKey ? (
                         <div>
                             <ReadOnlyMap
                                 width="100%"
                                 height={150}
                                 zoom={4}
                                 googleConfig={googleConfig}
                                 lat={this.state.lat}
                                 lng={this.state.lng}
                                 mapHeight={300} />
                         </div>

                     ) : null
                     }
                 </Collapse.Panel>
             </Collapse>
         );
     }
}

GuideMap.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(GuideMap);
