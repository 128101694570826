import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';

const routes = [
    {
        path: '',
        component: asyncComponent(() => import('../Widgets/index.js')),
    },
    {
        path: 'googlemap',
        component: asyncComponent(() => import('../Map/GoogleMap/googleMap')),
    },
    {
        path: 'leafletmap',
        component: asyncComponent(() => import('../Map/Leaflet/leaflet')),
    },
    {
        path: 'passwordchange',
        component: asyncComponent(() => import('../../customApp/containers/ChangePassword')),
    },
    {
        path: 'registrations',
        component: asyncComponent(() => import('../../customApp/containers/Registrations')),
    },
    {
        path: 'allFormComponent',
        component: asyncComponent(() => import('../Forms/allComponents')),
    },
    {
        path: 'InputField',
        component: asyncComponent(() => import('../Forms/Input')),
    },
    {
        path: 'editor',
        component: asyncComponent(() => import('../Forms/editor')),
    },
    {
        path: 'stepperForms',
        component: asyncComponent(() => import('../Forms/StepperForms')),
    },
    {
        path: 'FormsWithValidation',
        component: asyncComponent(() => import('../Forms/FormsWithValidation')),
    },
    {
        path: 'progress',
        component: asyncComponent(() => import('../Forms/Progress')),
    },
    {
        path: 'button',
        component: asyncComponent(() => import('../Forms/Button')),
    },
    {
        path: 'tab',
        component: asyncComponent(() => import('../Forms/Tab')),
    },
    {
        path: 'autocomplete',
        component: asyncComponent(() => import('../Forms/AutoComplete')),
    },
    {
        path: 'checkbox',
        component: asyncComponent(() => import('../Forms/Checkbox')),
    },
    {
        path: 'radiobox',
        component: asyncComponent(() => import('../Forms/Radiobox')),
    },
    {
        path: 'selectbox',
        component: asyncComponent(() => import('../Forms/Select')),
    },
    {
        path: 'transfer',
        component: asyncComponent(() => import('../Forms/Transfer')),
    },

    {
        path: 'notes',
        component: asyncComponent(() => import('../Notes')),
    },
    {
        path: 'todo',
        component: asyncComponent(() => import('../Todo')),
    },

    {
        path: 'alert',
        component: asyncComponent(() => import('../Feedback/Alert')),
    },
    {
        path: 'modal',
        component: asyncComponent(() => import('../Feedback/Modal')),
    },
    {
        path: 'message',
        component: asyncComponent(() => import('../Feedback/Message')),
    },
    {
        path: 'notification',
        component: asyncComponent(() => import('../Feedback/Notification')),
    },
    {
        path: 'Popconfirm',
        component: asyncComponent(() => import('../Feedback/Popconfirm')),
    },
    {
        path: 'spin',
        component: asyncComponent(() => import('../Feedback/Spin')),
    },
    {
        path: 'affix',
        component: asyncComponent(() => import('../Navigation/affix')),
    },
    {
        path: 'breadcrumb',
        component: asyncComponent(() => import('../Uielements/Breadcrumb/breadcrumb')),
    },
    {
        path: 'backToTop',
        component: asyncComponent(() => import('../Navigation/backToTop')),
    },
    {
        path: 'dropdown',
        component: asyncComponent(() => import('../Uielements/Dropdown/dropdown')),
    },
    {
        path: 'op_badge',
        component: asyncComponent(() => import('../Uielements/Badge')),
    },
    {
        path: 'op_card',
        component: asyncComponent(() => import('../Uielements/Card')),
    },
    {
        path: 'op_carousel',
        component: asyncComponent(() => import('../Uielements/Carousel')),
    },
    {
        path: 'op_collapse',
        component: asyncComponent(() => import('../Uielements/Collapse')),
    },
    {
        path: 'op_tooltip',
        component: asyncComponent(() => import('../Uielements/Tooltip')),
    },
    {
        path: 'rating',
        component: asyncComponent(() => import('../Uielements/rating')),
    },
    {
        path: 'tree',
        component: asyncComponent(() => import('../Uielements/Tree')),
    },
    {
        path: 'op_tag',
        component: asyncComponent(() => import('../Uielements/Tag')),
    },
    {
        path: 'op_timeline',
        component: asyncComponent(() => import('../Uielements/Timeline')),
    },
    {
        path: 'op_popover',
        component: asyncComponent(() => import('../Uielements/Popover')),
    },
    {
        path: 'googleChart',
        component: asyncComponent(() => import('../Charts/googleChart')),
    },
    {
        path: 'reecharts',
        component: asyncComponent(() => import('../Charts/recharts')),
    },
    {
        path: 'menu',
        component: asyncComponent(() => import('../Navigation/menu')),
    },
    {
        path: 'ReactChart2',
        component: asyncComponent(() => import('../Charts/reactChart2')),
    },
    {
        path: 'pagination',
        component: asyncComponent(() => import('../Uielements/Pagination/pagination')),
    },

    {
        path: 'reactDates',
        component: asyncComponent(() => import('../AdvancedUI/ReactDates/reactDates')),
    },
    {
        path: 'codeMirror',
        component: asyncComponent(() => import('../AdvancedUI/codeMirror')),
    },
    {
        path: 'uppy',
        component: asyncComponent(() => import('../AdvancedUI/uppy')),
    },
    {
        path: 'dropzone',
        component: asyncComponent(() => import('../AdvancedUI/dropzone')),
    },
    {
        path: 'frappeChart',
        component: asyncComponent(() => import('../Charts/frappeChart')),
    },
    {
        path: 'ergohubmap',
        component: asyncComponent(() => import('../../customApp/containers/ErgoHub/index')),
    },
    {
        path: 'ergohublist',
        component: asyncComponent(() => import('../../customApp/containers/ErgoHubList/index')),
    },

    {
        path: 'Templates_list',
        component: asyncComponent(() => import('../../customApp/containers/Templates')),
    },
    {
        path: 'Templates_create',
        component: asyncComponent(() => import('../../customApp/containers/TemplateCreate/index')),
    },
    {
        path: 'Templates_analytics',
        component: asyncComponent(() => import('../../customApp/containers/TemplateAnalytics/index')),
    },
    {
        path: 'UserProfile',
        component: asyncComponent(() => import('../../customApp/containers/UserProfile')),
    },
    {
        path: 'WorkEditor',
        component: asyncComponent(() => import('../../customApp/containers/Workeditor/index')),
    },
    {
        path: 'WorkPublisher',
        component: asyncComponent(() => import('../../customApp/containers/WorkPublisher/index')),
    },
    {
        path: 'WorkPreView',
        component: asyncComponent(() => import('../../customApp/containers/WorkPreview/index')),
    },
    {
        path: 'UserActivities',
        component: asyncComponent(() => import('../../customApp/containers/UserActivities/index')),
    },
    {
        path: 'UserActivities2',
        component: asyncComponent(() => import('../../customApp/containers/CompleteActivities/index')),
    },
    {
        path: 'UserOffers',
        component: asyncComponent(() => import('../../customApp/containers/Offers/index')),
    },
    {
        path: 'InviteUser',
        component: asyncComponent(() => import('../../customApp/containers/invitation/index')),
    },
    {
        path: 'PendingInvitations',
        component: asyncComponent(() => import('../../customApp/containers/Users/index')),
    },
    {
        path: 'RegisteredUsers',
        component: asyncComponent(() => import('../../customApp/containers/Users/index')),
    },


    ...customRoutes,
];

class AppRouter extends Component {
    render() {
        const { url, style } = this.props;
        return (
            <div style={style}>
                {routes.map((singleRoute) => {
                    const { path, exact, ...otherProps } = singleRoute;
                    return (
                        <Route
                            exact={exact !== false}
                            key={singleRoute.path}
                            path={`${url}/${singleRoute.path}`}
                            {...otherProps} />
                    );
                })}
            </div>
        );
    }
}

export default AppRouter;
