import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Collapse, Input, Typography, Checkbox, Select, Icon,
} from 'antd';
// import IntlMessages from '../../../components/utility/intlMessages';
import theme from '../../../../../settings/themes/themedefault';
import ButtonsComponent from '../ButtonsComponent';

const PanelHeader = {
    fontWeight: '300',
    fontSize: '0.8em',
    paddingTop: '0.2em',
    fontFamily: theme.fonts.primary,
};
const { Option } = Select;
// YesNo QUESTION OR ASSIGMENT COMPONENTS
class Measure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatory: props.element.mandatory,
            title: props.element.element_title,
            textNoFilled: true,
            measureType: '',
        };
    }

    componentDidMount() {
        const config1 = this.props.element.element_config;
        const config = JSON.parse(config1);

        if (config && config.measureType !== null) {
            this.setState({
                measureType: config.measureType,
                textNoFilled: false,
            });
        }
    }

    handleText = (event) => {
        this.setState({ title: event.target.value });
        setTimeout(() => { this.validate(); }, 1200);
    };

    validate = () => {
        const { mandatory, title, measureType } = this.state;
        const tit = title && title.length > 3 && true;
        const measureT = measureType && measureType.length > 3 && true;

        if (tit && measureT) {
            this.setState({ textNoFilled: false });
            this.props.handleSaveElement(this.props.element, this.state);
            return;
        }
        this.setState({ textNoFilled: true });
    }

    setType = (e) => {
        this.setState({ measureType: e });
        setTimeout(() => { this.validate(); }, 1200);
    }

    handleMandatory = (checkedValues) => {
        this.setState({ mandatory: checkedValues.target.checked });
        setTimeout(() => { this.validate(); }, 1200);
    };

    render() {
        const {
            element, activity,
        } = this.props;
        const { textNoFilled } = this.state;
        const empty = !textNoFilled && this.state.title ? 'white' : '#f7a6b5';
        const headerText = this.state.title ? this.state.title : 'Request a Measure number';

        const mais = (
            <div style={{maxWidth:150}}>
                <Icon style={{ fontSize: '16px', color: 'green', maxWidth: 50 }} type="pie-chart" />
                <h4>{headerText}</h4>
            </div>
        );

        return (
            <Collapse
                style={{ backgroundColor: empty }}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
                <Collapse.Panel
                    header={mais}
                    key={element.element_id}
                    showArrow={false}

                    extra={
                        (
                            <ButtonsComponent
                                color="danger"
                                iconame="pie-chart"
                                titlemod="Measure entity"
                                activity={activity}
                                enableSave={textNoFilled}
                                elementData={element}
                                elementState={this.state} />
                        )
                    }
                >
                    <Typography.Title style={PanelHeader}>
                        Enter title of your measurment -Select entity and save
                    </Typography.Title>
                    <Input placeholder={this.state.title} value={this.state.title} maxLength={50} onChange={this.handleText} />
                    <Typography.Title style={PanelHeader}> Select Type of meassure </Typography.Title>
                    <Select
                        style={{ minWidth: 180, marginLeft: 10 }}
                        placeholder="Types"
                        value={this.state.measureType}
                        onChange={this.setType}
                    >
                        <Option value="WeightKg">Weight KG</Option>
                        <Option value="WeightGrm">Weight Grm</Option>
                        <Option value="WeightLibs">Weight Libs</Option>
                        <Option value="SizeSqm">Size-SQM</Option>
                        <Option value="SizeLitters">Size-Litters</Option>
                        <Option value="SizeQubics">Size-Qubics</Option>
                        <Option value="DistanceMm">Distance-MM</Option>
                        <Option value="DistanceCm">Distance-CM</Option>
                        <Option value="DistanceKm">Distance-KM</Option>
                        <Option value="DistanceFoot">Distance-Foot</Option>
                        <Option value="DistanceMile">Distance-Mile</Option>
                    </Select>


                    <Checkbox
                        checked={this.state.mandatory}
                        onChange={this.handleMandatory}
                        style={{ color: 'red' }}
                    >
                    Required to submit ?
                    </Checkbox>
                </Collapse.Panel>
            </Collapse>
        );
    }
}

Measure.propTypes = {
};

function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps, {})(Measure);
