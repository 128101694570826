/* eslint-disable func-names */
import {
    all, takeEvery, put, fork,
} from 'redux-saga/effects';
import RegSingleActions from './actions';
import loginerror from '../auth/actions';
import { ergoBe } from '../../settings/index';
import { callDF } from '../../network/request';

export function* addRegSingle() {
    yield takeEvery(RegSingleActions.ADD_RegSingle, function* () {});
}
export function* editRegSingle() {
    yield takeEvery(RegSingleActions.EDIT_RegSingle, function* () {});
}
export function* deleteRegSingle() {
    yield takeEvery(RegSingleActions.DELETE__RegSingle, function* () {});
}
// TO DO LOAD WITH USER ID
export function* LoadRegSingle() {
    yield takeEvery(RegSingleActions.LOAD_RegSingle, function* (data) {
        const RegSingle = ergoBe.beUrl+"mongodb/_table/Registration?filter=owner_userid="+data.userid;
        const response = yield callDF(RegSingle);
        if (response.resource) {
            yield put({
                type: RegSingleActions.RegSingle_LOADED,
                RegSingle: response.resource,
            });
        } else {
            yield loginerror();
        }
    });
}
export default function* rootSaga() {
    yield all([fork(LoadRegSingle)]);
}
