import { SingleTemplate } from './reducer';

function ascendingSort(SingleTemplate1, SingleTemplate2) {
    const name1 = SingleTemplate1.name ? SingleTemplate1.name.toUpperCase() : '~';
    const name2 = SingleTemplate2.name ? SingleTemplate2.name.toUpperCase() : '~';
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const SingleTemplateActions = {
    LOAD_SingleTemplate: 'LOAD_SingleTemplate',
    SingleTemplate_LOADED: 'SingleTemplate_LOADED',
    ADD_SingleTemplate: 'ADD_SingleTemplate',
    EDIT_SingleTemplate: 'EDIT_SingleTemplate',
    DELETE__SingleTemplate: 'DELETE__SingleTemplate',
    CHANGE_SingleTemplate: 'CHANGE_SingleTemplate',
    EDIT_VIEW: 'EDIT_VIEW',

    changeSingleTemplate: id => ({
        type: SingleTemplateActions.CHANGE_SingleTemplate,
        id,
    }),
    
    addSingleTemplate: () => {
        const newSingleTemplate = {
            id: new Date(),
            firstName: '',
            avatar: SingleTemplate[new Date() % 10].avatar,
            LastName: '',
            mobile: '',
            home: '',
            name: '',
            company: '',
            work: '',
            note: '',

        };
        return (dispatch, getState) => {
            dispatch({
                type: SingleTemplateActions.ADD_SingleTemplate,
                SingleTemplate: [...getState().SingleTemplate.SingleTemplate, newSingleTemplate],
                selectedId: newSingleTemplate.id,
            });
        };
    },
    
    editSingleTemplate: newSingleTemplate => (dispatch, getState) => {
        const SingleTemplate = getState().SingleTemplate.SingleTemplate;
        const newSingleTemplate = [];
        SingleTemplate.forEach((SingleTemplate) => {
            if (SingleTemplate.id === newSingleTemplate.id) {
                newSingleTemplate.push(newSingleTemplate);
            } else {
                newSingleTemplate.push(SingleTemplate);
            }
        });
        dispatch({
            type: SingleTemplateActions.EDIT_SingleTemplate,
            SingleTemplate: newSingleTemplate.sort(ascendingSort),
        });
    },
    deleteSingleTemplate: id => (dispatch, getState) => {
        const SingleTemplate = getState().SingleTemplate.SingleTemplate;
        const seectedId = getState().SingleTemplate.seectedId;
        const newSingleTemplate = [];
        SingleTemplate.forEach((SingleTemplate) => {
            if (SingleTemplate.id === id) {
            } else {
                newSingleTemplate.push(SingleTemplate);
            }
        });
        dispatch({
            type: SingleTemplateActions.DELETE__SingleTemplate,
            SingleTemplate: newSingleTemplate,
            seectedId: id === seectedId ? undefined : seectedId,
        });
    },
    viewChange: view => ({
        type: SingleTemplateActions.EDIT_VIEW,
        view,
    }),

    LoadSingleTemplate: id => (dispatch) => {
        dispatch({
            type: SingleTemplateActions.LOAD_SingleTemplate,
            Temmplateid: id,
            SingleTemplate,
        });
    },
    SingleTemplateLoaded: () => ({
        type: SingleTemplateActions.SingleTemplate_LOADED,
        SingleTemplate,

    }),
};
export default SingleTemplateActions;
