import { language } from '../../settings';

import englishLang from '../../image/flag/uk.svg';
import greekLang from '../../image/flag/greek.png';

const config = {
    defaultLanguage: language,
    options: [
        {
            languageId: 'english',
            locale: 'en',
            text: 'English',
            icon: englishLang,
        },
        {
            languageId: 'greek',
            locale: 'gr',
            text: 'Greek',
            icon: greekLang,
        },

    ],
};

export function getCurrentLanguage(lang) {
    let selecetedLanguage = config.options[0];
    config.options.forEach((language) => {
        if (language.languageId === lang) {
            selecetedLanguage = language;
        }
    });
    return selecetedLanguage;
}
export default config;
